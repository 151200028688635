import {XMarkIcon} from '@heroicons/react/20/solid';
import {Button} from '@nextui-org/react';
import {closeSnackbar, enqueueSnackbar, SnackbarKey} from 'notistack';
import React from 'react';

export function useNotifications() {
  const showSuccess = (message: string) => {
    enqueueSnackbar(message, {
      variant: 'success',
      hideIconVariant: true,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      autoHideDuration: 3000,
    });
  };

  const showWarning = (message: string) => {
    enqueueSnackbar(message, {
      variant: 'warning',
      hideIconVariant: true,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      autoHideDuration: 3000,
    });
  };

  const showError = (message: string, onClose?: () => void) => {
    const action = (snackbarId: SnackbarKey | undefined) => (
      <Button
        className="bg-[#d32f2f]"
        onClick={() => {
          closeSnackbar(snackbarId);
          onClose?.();
        }}
        isIconOnly
      >
        <XMarkIcon className="text-white" />
      </Button>
    );

    enqueueSnackbar(message, {
      variant: 'error',
      hideIconVariant: true,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      autoHideDuration: null,
      action,
      onClose,
    });
  };

  return {showSuccess, showWarning, showError};
}
