import {XMarkIcon} from '@heroicons/react/20/solid';
import {
  Button,
  Chip,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Select,
  SelectItem,
  Switch,
} from '@nextui-org/react';
import React, {FC, useEffect, useMemo, useState} from 'react';

import {Dataset} from '../types/Dataset';
import {DatasetRow} from '../types/DatasetRow';

export interface ImportExamplesFromJsonModalProps {
  isOpen: boolean;
  onClose: () => void;
  onImport: (examples: Array<DatasetRow>) => void;
  importedRows: Array<DatasetRow>;
  dataset: Dataset;
}

const ImportExamplesFromJsonModal: FC<ImportExamplesFromJsonModalProps> = ({
  importedRows,
  isOpen,
  onClose,
  onImport,
  dataset,
}) => {
  const [mapping, setMapping] = useState<Record<string, string>>({});
  const datasetImportedKeys = useMemo(() => {
    if (!importedRows || importedRows.length === 0) {
      return [];
    }

    return [...Object.keys(importedRows[0].input), 'expected_output'];
  }, [importedRows]);
  const [allLines, setAllLines] = useState(false);
  const [selectedLines, setSelectedLines] = useState({
    from: 0,
    to: 1,
  });

  useEffect(() => {
    setSelectedLines({
      from: 0,
      to: importedRows.length - 1,
    });
  }, [importedRows]);

  useEffect(() => {
    if (!dataset || !dataset.inputs || dataset.inputs.length === 0) {
      return;
    }

    const newMapping = [...dataset.inputs, 'expected_output'].reduce(
      (acc, input) => {
        acc[input] = datasetImportedKeys.includes(input) ? input : '';
        return acc;
      },
      {} as Record<string, string>,
    );

    setMapping(newMapping);
  }, [dataset, datasetImportedKeys]);

  const processImportedRows = () => {
    if (!importedRows || importedRows.length === 0) {
      return;
    }

    const rowsToProcess = allLines
      ? importedRows
      : importedRows.slice(selectedLines.from, selectedLines.to);

    const resultArray = rowsToProcess.map(row => {
      const input = {} as Record<string, string>;

      Object.keys(mapping).forEach(key => {
        const mappedKey = mapping[key];
        if (mappedKey && row.input[mappedKey] !== undefined) {
          input[key] = row.input[mappedKey];
        }
      });

      const expected_output = row.expected_output || '';

      return {input, expected_output};
    });

    onImport(resultArray as DatasetRow[]);

    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      hideCloseButton={true}
      isDismissable={false}
      size="4xl"
      radius="none"
    >
      <ModalContent className="flex flex-col items-start justify-start bg-white">
        <ModalHeader className="flex w-full flex-row items-center justify-between bg-[#F9FAFB]">
          <h3 className="font-roboto text-sm font-medium text-[#374151]">
            Import JSON
          </h3>
          <Button
            isIconOnly={true}
            onClick={onClose}
            aria-label="close"
            radius="full"
            className="h-8 w-8 min-w-0 self-start bg-[#F9FAFB]"
          >
            <XMarkIcon className="h-5" />
          </Button>
        </ModalHeader>
        <ModalBody className="flex w-full flex-col items-start justify-start p-6">
          <Switch
            color="default"
            className="font-chivo mt-3 text-xs text-[#1E1E1E]"
            isSelected={allLines}
            onValueChange={setAllLines}
            classNames={{
              wrapper: `${allLines ? '!bg-[#485ED5]' : 'bg-[#9CA3AF]'}`,
            }}
          >
            Include all lines
          </Switch>
          <div className="mt-3 flex flex-col items-start justify-start">
            <span className="font-chivo text-sm font-medium text-[#374151]">
              Select Lines from dataset
            </span>
            <div className="mt-2 flex flex-row items-center justify-between gap-2">
              <Input
                radius="none"
                type="number"
                aria-label="lines-range-from"
                placeholder="1"
                disabled={allLines}
                value={(selectedLines.from + 1).toString()}
                onValueChange={value => {
                  setSelectedLines(prevState => ({
                    ...prevState,
                    from: Math.max(0, parseInt(value) - 1),
                  }));
                }}
                startContent={
                  <span className="font-chivo text-xs font-normal text-[#1E1E1E]">
                    From
                  </span>
                }
                classNames={{
                  inputWrapper: `${!allLines ? 'bg-white' : 'bg-[#c0c0c0]'} border-[#E9E8E9] border-1`,
                }}
              />
              <Input
                radius="none"
                type="number"
                aria-label="lines-range-to"
                disabled={allLines}
                placeholder={importedRows.length.toString()}
                value={(selectedLines.to + 1).toString()}
                onValueChange={value => {
                  setSelectedLines(prevState => ({
                    ...prevState,
                    to: Math.min(importedRows.length, parseInt(value) - 1),
                  }));
                }}
                startContent={
                  <span className="font-chivo text-xs font-normal text-[#1E1E1E]">
                    To
                  </span>
                }
                classNames={{
                  inputWrapper: `${!allLines ? 'bg-white' : 'bg-[#c0c0c0]'} border-[#E9E8E9] border-1`,
                }}
              />
            </div>
          </div>
          <div className="mt-3 flex flex-col items-start justify-start">
            <span className="font-chivo text-sm font-medium text-[#374151]">
              Map variables to inputs from dataset
            </span>
            <div className="mt-3 grid grid-cols-[2fr_3fr] gap-2">
              <span className="font-chivo text-xs font-normal text-[#1E1E1E]">
                Variable
              </span>
              <span className="font-chivo text-xs font-normal text-[#1E1E1E]">
                Input from dataset
              </span>
              {Object.keys(mapping).map(input => (
                <>
                  <div className="flex flex-row items-center justify-center">
                    <Chip
                      key={input}
                      color="default"
                      radius="none"
                      classNames={{
                        base: 'w-full max-w-full bg-[#485ED533] rounded-[3px] text-[#485ED5]',
                        content: 'font-chivo text-center text-xs font-semibold',
                      }}
                    >
                      {input}
                    </Chip>
                  </div>
                  <div className="flex flex-row items-center justify-center">
                    <Select
                      aria-label="Select Input"
                      key={input}
                      selectedKeys={new Set([mapping[input]])}
                      radius="none"
                      placeholder="Select Input"
                      selectionMode="single"
                      classNames={{
                        trigger: 'bg-white border-[#E9E8E9] border-1',
                        popoverContent: 'rounded-none',
                      }}
                      onSelectionChange={value => {
                        const selectedKey = String(Array.from(value)[0]);
                        setMapping(prevState => ({
                          ...prevState,
                          [input]: selectedKey,
                        }));
                      }}
                    >
                      {datasetImportedKeys.map(key => (
                        <SelectItem key={key} value={key}>
                          {key}
                        </SelectItem>
                      ))}
                    </Select>
                  </div>
                </>
              ))}
            </div>
          </div>
          <div className="mb-4 mt-10 flex w-full flex-row items-center justify-center">
            <Button
              aria-label="Import"
              className="bg-[#485ED5] px-14 py-6 text-white"
              radius="none"
              onClick={processImportedRows}
            >
              Import
            </Button>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ImportExamplesFromJsonModal;
