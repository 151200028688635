import {ChevronRightIcon} from '@heroicons/react/20/solid';
import {Button} from '@nextui-org/react';
import React, {useState, useEffect, useRef} from 'react';

import {getPrompts} from '../PromptServerClient';
import PromptsComponent from '../components/SamplePrompts';
import {useNotifications} from '../notifications';
import useStore, {StorageKeys} from '../store';
import {formatToLocalDate} from '../utils/string';

interface PromptItemProps {
  prompt_id: string;
  name: string;
  created_at: string;
}

const PromptItem: React.FC<PromptItemProps> = ({
  prompt_id,
  name,
  created_at,
}) => (
  <a href={`/prompts/${prompt_id}`} key={prompt_id}>
    <li className="hover:scale-101 my-4 rounded-sm p-4 outline outline-gray-100 transition-all duration-300 ease-in-out hover:cursor-pointer hover:bg-gray-50">
      <div className="group relative flex items-start space-x-4 lg:space-x-6">
        <div className="min-w-0 flex-1 text-left">
          <div className="text-sm font-medium text-gray-900">{name}</div>
          <div className="text-xs font-medium text-gray-400">
            {formatToLocalDate(created_at)}
          </div>
        </div>
        <div className="flex-shrink-0 self-center">
          <ChevronRightIcon
            className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"
          />
        </div>
      </div>
    </li>
  </a>
);

const MigratePromptSection: React.FC = () => <PromptsComponent />;

interface Prompt {
  prompt_id: string;
  name: string;
  created_at: string;
}

interface PromptsSectionProps {
  prompts: Prompt[];
  loadingPrompts: boolean;
}

const PromptsSection: React.FC<PromptsSectionProps> = ({
  prompts,
  loadingPrompts,
}) => (
  <div>
    <div className="bg-white px-6 py-2">
      {!prompts.length && loadingPrompts ? (
        <div className="mt-4 text-center text-gray-500">Loading prompts...</div>
      ) : prompts.length ? (
        <ul role="list" className="mt-2 divide-y divide-gray-200">
          {prompts.map((prompt, index) => (
            <PromptItem
              key={index}
              prompt_id={prompt.prompt_id}
              name={prompt.name}
              created_at={prompt.created_at}
            />
          ))}
        </ul>
      ) : (
        <div className="mt-4 py-4 text-center text-gray-500">
          <p className="mb-4">
            You don't have any prompts loaded yet. Add one to get started!
          </p>
        </div>
      )}
    </div>
  </div>
);

const DashboardPage: React.FC<any> = ({}) => {
  const [loadingPrompts, setLoadingPrompts] = useState<boolean>(true);
  const [prompts, setPrompts] = useState<Prompt[]>(() => {
    const storedPrompts = localStorage.getItem(StorageKeys.MAGICPROMPT_PROMPTS);
    return storedPrompts ? JSON.parse(storedPrompts) : [];
  });
  const session = useStore(state => state.session);

  const initialFetchDone = useRef(false);

  const {showError} = useNotifications();

  useEffect(() => {
    if (session) {
      const fetchAndSetPrompts = async () => {
        if (initialFetchDone.current) {
          return;
        }

        setLoadingPrompts(true);
        initialFetchDone.current = true;
        try {
          const promptsResponse = await getPrompts();

          setPrompts(promptsResponse);
          localStorage.setItem(
            StorageKeys.MAGICPROMPT_PROMPTS,
            JSON.stringify(promptsResponse),
          );
        } catch (error) {
          showError('Failed to fetch prompts');
          console.error('Failed to fetch prompts:', error);
        } finally {
          setLoadingPrompts(false);
        }
      };

      fetchAndSetPrompts();
    }
  }, [session]);

  return (
    <div className="relative min-h-screen bg-white">
      <div className="flex items-center justify-between p-6">
        <h3 className="font-chivo text-left text-2xl text-gray-900">Prompts</h3>
        <Button
          color="primary"
          radius="none"
          onClick={() => (window.location.href = '/prompts/create')}
        >
          New Prompt
        </Button>
      </div>
      <MigratePromptSection />
      <PromptsSection prompts={prompts} loadingPrompts={loadingPrompts} />
    </div>
  );
};

export default DashboardPage;
