import {useEffect, useMemo, useState} from 'react';

import axiosInstance from './axiosInstance';

export type modelListParams = {
  modelName?: string;
  inputTokens?: number;
  outputTokens?: number;
};

export type ModelMetadata = {
  comparison?: {
    absoluteDifference: number;
    percentageDifference: number;
  };
  provider: string;
  contextSize: number;
  class: string;
  inputCost: number;
  outputCost: number;
  rank: number;
  label: string;
  asMainModel: boolean;
  asTrialModel: boolean;
};

export type ModelsOptions = {
  name: string;
  value: string;
};

export type ModelsList = Record<string, ModelMetadata>;

export function useModelsList() {
  const [loading, setLoading] = useState(false);
  const [defaultModelsList, setDefaultModelsList] = useState<ModelsList>({});

  const get = async (params?: modelListParams) => {
    setLoading(true);

    try {
      const response = await axiosInstance.get('/models', {params});

      setLoading(false);

      return response.data;
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  const modelOptions: ModelsOptions[] = useMemo(() => {
    return Object.keys(defaultModelsList).map(key => {
      const model = defaultModelsList[key];

      return {
        name: model.label,
        value: key,
      };
    });
  }, [defaultModelsList]);

  const getTrialModels = () => {
    return modelOptions.filter(option => {
      const model = defaultModelsList[option.value];
      return model.asTrialModel;
    });
  };

  const getMainModels = () => {
    return modelOptions.filter(option => {
      const model = defaultModelsList[option.value];
      return model.asMainModel;
    });
  };

  useEffect(() => {
    if (Object.keys(defaultModelsList).length > 0) {
      return;
    }

    const fetchDefaultModelsList = async () => {
      try {
        const response = await get();
        setDefaultModelsList(response);
      } catch (error) {
        console.error('Error fetching default models list:', error);
      }
    };

    void fetchDefaultModelsList();
  });

  return {
    loading,
    get,
    defaultModelsList,
    modelOptions,
    getTrialModels,
    getMainModels,
  };
}
