import {Button, Input, Link} from '@nextui-org/react';
import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {savePrompt} from '../PromptServerClient';
import PromptEditor from '../components/PromptEditor';
import {useNotifications} from '../notifications';

export default function NewPromptPage({onSuccess}: any) {
  const [promptName, setPromptName] = useState<string>('');
  const [messages, setMessages] = useState([
    {
      role: 'system',
      content: '',
    },
  ]);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const {showError, showSuccess} = useNotifications();

  useEffect(() => {
    const storedData = localStorage.getItem('temp_prompt_storage');
    if (storedData) {
      const {promptNode, selectedTrainingModel} = JSON.parse(storedData);
      if (promptNode && promptNode.prompt) {
        setMessages([
          {
            role: 'system',
            content: promptNode.prompt,
          },
        ]);
      }
      if (selectedTrainingModel) {
        setPromptName(`New Prompt - ${selectedTrainingModel}`);
      }
      localStorage.removeItem('temp_prompt_storage');
    }
  }, []);

  const isPromptValid = () => {
    return (
      promptName.trim() !== '' &&
      messages.some(msg => msg.content.trim() !== '')
    );
  };

  const handleSavePrompt = async () => {
    if (!isPromptValid()) {
      setError('Prompt name and content are required');
      return;
    }

    setError(null);

    try {
      const savedPrompt = await savePrompt({
        content: messages,
        name: promptName,
      });
      if (savedPrompt && savedPrompt.promptId) {
        showSuccess('Prompt saved');
        onSuccess();
        navigate(`/prompts/${savedPrompt.promptId}`);
      } else {
        setError('Failed to save prompt');
      }
    } catch (error) {
      showError('Error saving prompt. Please try again.');
      setError('Error saving prompt. Please try again.');
    }
  };

  return (
    <div className="relative pt-[4.5rem]">
      <div className="mx-auto">
        <div className="-mt-[4.5rem] min-h-screen bg-white p-6">
          <div className="mb-2 max-w-[60%] text-left text-sm">
            <Link
              href="/"
              className="mb-2 text-sm text-black text-blue-600 hover:underline"
            >
              Prompts
            </Link>
            <h1 className="font-chivo mb-4 text-xl">Create New Prompt</h1>
            <Input
              radius="none"
              value={promptName}
              onChange={e => setPromptName(e.target.value)}
              placeholder="Enter Your Prompt Name..."
              fullWidth
              size="lg"
              className="mb-4"
            />
            <PromptEditor
              messages={messages}
              onChange={newMessages => setMessages(newMessages)}
            />
            {error && (
              <div
                className="relative mt-4 rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700"
                role="alert"
              >
                <span className="block sm:inline">{error}</span>
              </div>
            )}
            <Button
              onClick={handleSavePrompt}
              className="mt-4"
              color="primary"
              radius="none"
            >
              Create Prompt
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
