import {EllipsisVerticalIcon} from '@heroicons/react/24/outline';
import {
  BreadcrumbItem,
  Breadcrumbs,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Textarea,
} from '@nextui-org/react';
import React, {FC, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import axiosInstance from '../axiosInstance';
import {useNotifications} from '../notifications';
import {Dataset} from '../types/Dataset';
import {DatasetRow} from '../types/DatasetRow';

const DatasetRowDetailsPage: FC = () => {
  const [datasetId, setDatasetId] = useState('');
  const [rowId, setRowId] = useState('');
  const [dataset, setDataset] = useState<Dataset | null>(null);
  const [editing, setEditing] = useState(false);
  const [row, setRow] = useState<null | DatasetRow>(null);

  const navigate = useNavigate();
  const {showSuccess, showError} = useNotifications();

  useEffect(() => {
    const datasetId = window.location.pathname.split('/')[2];
    setDatasetId(datasetId);

    const rowId = window.location.pathname.split('/').pop();

    if (rowId && rowId !== 'new') {
      setRowId(rowId);
    }

    if (rowId === 'new') {
      setEditing(true);
    }
  }, []);

  useEffect(() => {
    // create and set row with empty values on dataset fetch
    if (!rowId || rowId === 'new') {
      const newRow: DatasetRow = {
        inputs: {},
        expected_output: '',
      } as DatasetRow;
      for (const input of dataset?.inputs || []) {
        newRow.inputs![input] = '';
      }

      setRow(newRow);
      return;
    }
  }, [dataset]);

  useEffect(() => {
    const fetchDataset = async () => {
      if (!datasetId) {
        return;
      }

      try {
        const {data} = await axiosInstance.get(`/datasets/${datasetId}`);

        setDataset(data);
      } catch (e) {
        showError('Error fetching dataset');
        console.error('Error fetching dataset:', e);
      }
    };

    void fetchDataset();
  }, [datasetId]);

  const saveNewRow = async () => {
    const {expected_output, ...rest} = row;

    const body = {
      inputs: rest.inputs,
      expectedOutput: expected_output,
    };

    try {
      const response = await axiosInstance.post(
        `/datasets/${datasetId}/rows`,
        body,
      );

      const rowId = response.data[0].id;

      navigate(`/datasets/${datasetId}/rows/${rowId}`);

      setRowId(rowId);

      showSuccess('Row saved');
    } catch (e) {
      showError('Error saving row');
      console.error('Error saving row:', e);
    }
  };

  const saveEditedRow = async () => {
    try {
      if (row) {
        await axiosInstance.patch(`/datasets/${datasetId}/rows/${rowId}`, {
          inputs: row.inputs,
          expectedOutput: row.expected_output,
        });
      }

      showSuccess('Row saved');
    } catch (e) {
      showError('Error saving row');
      console.error('Error saving row:', e);
    }
  };

  const handleSave = async () => {
    if (!rowId || rowId === 'new') {
      return await saveNewRow();
    }

    return await saveEditedRow();
  };

  useEffect(() => {
    const fetchRow = async () => {
      if (!rowId || rowId === 'new') {
        const newRow = {} as DatasetRow;
        for (const input of dataset?.inputs || []) {
          newRow[input] = '';
        }

        setRow(newRow);
        return;
      }

      try {
        const {data} = await axiosInstance.get(
          `/datasets/${datasetId}/rows/${rowId}`,
        );

        setRow({
          ...data,
          inputs: data.input,
        });
      } catch (e) {
        showError('Error fetching row');
        console.error('Error fetching row:', e);
      }
    };

    void fetchRow();
  }, [rowId]);

  const handleDeleteRow = async () => {
    try {
      await axiosInstance.delete(`/datasets/${datasetId}/rows/${rowId}`);

      showSuccess('Row deleted');
      navigate(`/datasets/${datasetId}`);
    } catch (e) {
      showError('Error deleting row');
      console.error('Error deleting row:', e);
    }
  };

  return (
    <div className="relative min-h-screen bg-white">
      <div className="flex w-full flex-col items-start justify-start p-6">
        <Breadcrumbs>
          <BreadcrumbItem href="/datasets">
            <span className="font-chivo text-medium font-medium text-[#303A9D]">
              Datasets
            </span>
          </BreadcrumbItem>
          <BreadcrumbItem href={`/datasets/${datasetId}`}>
            <span className="font-chivo text-medium font-medium text-[#303A9D]">
              {dataset?.name}
            </span>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <span className="font-chivo text-medium font-medium text-[#111827]">
              {rowId ? `Row (${rowId})` : 'New Row'}
            </span>
          </BreadcrumbItem>
        </Breadcrumbs>
        <div className="mt-3 flex w-full flex-row flex-nowrap items-center justify-between">
          <h1 className="font-chivo text-2xl font-normal text-[#111827]">
            {rowId ? `Row (${rowId})` : 'New Row'}
          </h1>
          <div className="flex gap-2">
            <Button
              radius="none"
              aria-label="edit-save-button"
              className="border-2 border-[#D1D5DB] bg-white"
              onClick={() => {
                if (editing) {
                  void handleSave();
                }

                setEditing(!editing);
              }}
            >
              <span className="font-chivo text-sm font-medium">
                {editing ? 'Save' : 'Edit'}
              </span>
            </Button>
            <Dropdown radius="none">
              <DropdownTrigger>
                <Button
                  aria-label="more-options-button"
                  radius="none"
                  className="ml-3 border-2 border-[#D1D5DB] bg-white"
                  isIconOnly
                >
                  <EllipsisVerticalIcon className="h-4 w-4" />
                </Button>
              </DropdownTrigger>
              <DropdownMenu>
                <DropdownItem
                  key={'delete'}
                  className="font-chivo text-right text-sm font-medium text-[#F31260]"
                  onClick={handleDeleteRow}
                >
                  Delete Row
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        <div className="mt-3 flex w-full flex-col flex-nowrap items-start justify-start">
          {row &&
            row.inputs &&
            Object.keys(row.inputs).map((input: string, index: number) => (
              <div className="mt-6 w-full" key={index}>
                <Textarea
                  key={input}
                  aria-label={input}
                  label={input}
                  labelPlacement="outside"
                  value={row.inputs[input]}
                  className="w-2/3 items-start justify-start"
                  classNames={{
                    label: 'font-chivo text-sm',
                    input: 'bg-white',
                    inputWrapper:
                      'bg-[#F4F4F5] border-1 border-[#E9E8E9] font-chivo text-[#374151]',
                  }}
                  radius="none"
                  placeholder="Enter value..."
                  onValueChange={(value: string) => {
                    setRow(prevState => {
                      return {
                        ...prevState,
                        inputs: {
                          ...prevState?.inputs,
                          [input]: value,
                        },
                      };
                    });
                  }}
                  readOnly={!editing}
                />
              </div>
            ))}
          <div className="mt-6 w-full">
            <Textarea
              aria-label="expected-output"
              label="Expected Output"
              labelPlacement="outside"
              value={(row && row.expected_output) || ''}
              className="w-2/3 items-start justify-start"
              classNames={{
                label: 'font-chivo text-sm',
                input: 'bg-white',
                inputWrapper:
                  'bg-[#F4F4F5] border-1 border-[#E9E8E9] font-chivo text-[#374151] p-3 px-4',
              }}
              radius="none"
              placeholder="Enter value..."
              readOnly={!editing}
              onValueChange={(value: string) => {
                setRow(prevState => {
                  if (!prevState) {
                    return null;
                  }

                  return {
                    ...prevState,
                    expected_output: value,
                  };
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DatasetRowDetailsPage;
