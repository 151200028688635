import {XMarkIcon} from '@heroicons/react/20/solid';
import {
  Button,
  Chip,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Select,
  SelectItem,
  Switch,
} from '@nextui-org/react';
import omit from 'lodash/omit';
import React, {FC, useEffect, useMemo, useState} from 'react';

import {Task} from '../types/Task';

export type ImportFromJsonFileModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onImport: (data: Task) => void;
  jsonData: Task;
  fileName: string;
  inputs: string[];
};

export const ImportFromJsonFileModal: FC<ImportFromJsonFileModalProps> = ({
  onClose,
  isOpen,
  onImport,
  jsonData,
  fileName,
  inputs,
}) => {
  const [allLines, setAllLines] = useState(true);
  const [selectedLines, setSelectedLines] = useState({
    from: 0,
    to: jsonData?.examples?.length - 1 || 0,
  });
  const [mapping, setMapping] = useState<Record<string, string>>({});
  const [task, setTask] = useState({});

  useEffect(() => {
    setSelectedLines({
      from: 0,
      to: (jsonData?.examples?.length || 0) - 1 || 0,
    });
  }, [jsonData]);

  useEffect(() => {
    if (
      !jsonData ||
      !jsonData.examples ||
      jsonData.examples.length === 0 ||
      !inputs ||
      inputs.length === 0
    ) {
      setMapping({});
    }

    if (inputs?.length > 0) {
      const mapping = [...inputs, 'expected_output'].reduce((acc, input) => {
        if (input === 'expected_output') {
          acc[input] = importedDatasetKeys.includes('target') ? 'target' : '';
          return acc;
        }
        acc[input] = importedDatasetKeys.includes(input)
          ? input
          : importedDatasetKeys[0];
        return acc;
      }, {});
      setMapping(mapping);
    } else if (jsonData?.examples.length > 0) {
      setMapping({
        input: importedDatasetKeys[0],
        expected_output: 'target',
      });
    }
  }, [inputs, jsonData]);

  const importedDatasetKeys = useMemo(() => {
    if (!jsonData || !jsonData.examples || jsonData.examples.length === 0) {
      return ['target'];
    }

    return [...Object.keys(jsonData.examples[0].input), 'target'];
  }, [jsonData]);

  useEffect(() => {
    // form task
    const newTask = {...omit(jsonData, 'examples')};

    const selectedExamples = allLines
      ? jsonData?.examples
      : jsonData?.examples.slice(selectedLines.from, selectedLines.to + 1);

    newTask.examples = selectedExamples?.map(example => {
      if (
        Object.keys(mapping).length === 2 &&
        Object.keys(mapping).includes('input') &&
        Object.keys(mapping).includes('expected_output')
      ) {
        return {
          input: example.input[mapping.input],
          target:
            example.input[mapping.expected_output] ||
            example.target ||
            example.expected_output,
        };
      }

      const input = {} as Record<string, string>;

      Object.keys(mapping).forEach(key => {
        const mappedKey = mapping[key];
        if (mappedKey && example.input[mappedKey]) {
          input[key] = example.input[mappedKey];
        }
      });

      const expected_output =
        example.input[mapping.expected_output] || example.target;

      return {
        input,
        target: expected_output,
      };
    });

    setTask(newTask);
  }, [mapping, selectedLines]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      hideCloseButton
      isDismissable={false}
      size="4xl"
      radius="none"
    >
      <ModalContent className="flex flex-col items-start justify-start bg-white">
        <ModalHeader className="flex w-full flex-row items-center justify-between bg-[#F9FAFB]">
          <h3 className="font-roboto text-sm font-medium text-[#374151]">
            Import Dataset from {fileName}
          </h3>
          <Button
            isIconOnly={true}
            onClick={onClose}
            radius="full"
            className="h-8 w-8 min-w-0 self-start bg-[#F9FAFB]"
          >
            <XMarkIcon className="h-5" />
          </Button>
        </ModalHeader>
        <ModalBody className="flex w-full flex-col items-start justify-start p-6">
          <Switch
            color="default"
            className="font-chivo mt-3 text-xs text-[#1E1E1E]"
            isSelected={allLines}
            onValueChange={setAllLines}
            classNames={{
              wrapper: `${allLines ? '!bg-[#485ED5]' : 'bg-[#9CA3AF]'}`,
            }}
          >
            Include all lines
          </Switch>
          <div className="mt-3 flex flex-col items-start justify-start">
            <span className="font-chivo text-sm font-medium text-[#374151]">
              Select Lines from dataset
            </span>
            <div className="mt-2 flex flex-row items-center justify-between gap-2">
              <Input
                radius="none"
                type="number"
                aria-label="lines-range-from"
                placeholder="1"
                disabled={allLines}
                value={(selectedLines.from + 1).toString()}
                onValueChange={value => {
                  setSelectedLines(prevState => ({
                    ...prevState,
                    from: Math.max(0, parseInt(value) - 1),
                  }));
                }}
                startContent={
                  <span className="font-chivo text-xs font-normal text-[#1E1E1E]">
                    From
                  </span>
                }
                classNames={{
                  inputWrapper: `${!allLines ? 'bg-white' : 'bg-[#c0c0c0]'} border-[#E9E8E9] border-1`,
                }}
              />
              <Input
                radius="none"
                type="number"
                aria-label="lines-range-to"
                disabled={allLines}
                placeholder={jsonData?.examples.length.toString()}
                value={(selectedLines.to + 1).toString()}
                onValueChange={value => {
                  setSelectedLines(prevState => ({
                    ...prevState,
                    to: Math.max(
                      selectedLines.from,
                      Math.min(
                        jsonData.examples.length || 0,
                        parseInt(value) - 1,
                      ),
                    ),
                  }));
                }}
                startContent={
                  <span className="font-chivo text-xs font-normal text-[#1E1E1E]">
                    To
                  </span>
                }
                classNames={{
                  inputWrapper: `${!allLines ? 'bg-white' : 'bg-[#c0c0c0]'} border-[#E9E8E9] border-1`,
                }}
              />
            </div>
          </div>
          <div className="mt-3 flex flex-col items-start justify-start">
            <span className="font-chivo text-sm font-medium text-[#374151]">
              Map variables to inputs from dataset
            </span>
            <div className="mt-3 grid grid-cols-[2fr_3fr] gap-2">
              <span className="font-chivo text-xs font-normal text-[#1E1E1E]">
                Variable
              </span>
              <span className="font-chivo text-xs font-normal text-[#1E1E1E]">
                Input from dataset
              </span>
              {Object.keys(mapping).map(input => (
                <>
                  <div className="flex flex-row items-center justify-center">
                    <Chip
                      key={input}
                      color="default"
                      radius="none"
                      classNames={{
                        base: 'w-full max-w-full bg-[#485ED533] rounded-[3px] text-[#485ED5]',
                        content: 'font-chivo text-center text-xs font-semibold',
                      }}
                    >
                      {input}
                    </Chip>
                  </div>
                  <div className="flex flex-row items-center justify-center">
                    <Select
                      aria-label="Select Input"
                      key={input}
                      selectedKeys={new Set([mapping[input]])}
                      radius="none"
                      placeholder="Select Input"
                      selectionMode="single"
                      classNames={{
                        trigger: 'bg-white border-[#E9E8E9] border-1',
                        popoverContent: 'rounded-none',
                      }}
                      onSelectionChange={value => {
                        const selectedKey = String(Array.from(value)[0]);
                        setMapping(prevState => ({
                          ...prevState,
                          [input]: selectedKey,
                        }));
                      }}
                    >
                      {importedDatasetKeys.map((key: string) => (
                        <SelectItem key={key} value={key}>
                          {key}
                        </SelectItem>
                      ))}
                    </Select>
                  </div>
                </>
              ))}
            </div>
          </div>
          <div className="mb-4 mt-10 flex w-full flex-row items-center justify-center">
            <Button
              aria-label="Import"
              className="bg-[#485ED5] px-14 py-6 text-white"
              radius="none"
              onClick={() => {
                onImport(task);
                onClose();
              }}
            >
              Import
            </Button>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
