import {Menu} from '@headlessui/react';
import {CircleStackIcon} from '@heroicons/react/24/outline';
import {
  DocumentTextIcon,
  BeakerIcon,
  Cog6ToothIcon,
  BookOpenIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/solid';
import {Session} from '@supabase/supabase-js';
import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';

import {getUserTeam} from '../PromptServerClient';
import {classNames} from '../utils/css';

// Define the structure for navigation items
interface NavigationItem {
  name: string;
  href: string;
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

// Define the structure for user navigation items
interface UserNavigationItem {
  name: string;
  href: string;
  onClick: () => void;
}

// Define the props for the Sidebar component
interface SidebarProps {
  session: Session;
  userNavigation: UserNavigationItem[];
  isLoading: boolean;
  GettingStartedCard: any;
}

const Sidebar: React.FC<SidebarProps> = ({
  session,
  userNavigation,
  isLoading,
  GettingStartedCard,
}) => {
  const location = useLocation();
  const [superblocksIntegrationEnabled, setSuperblocksIntegrationEnabled] =
    useState(false);

  // Define the main navigation items
  const navigation: NavigationItem[] = [
    {name: 'Prompts', href: '/prompts', icon: DocumentTextIcon},
    {name: 'Optimize', href: '/training/prompts', icon: BeakerIcon},
    {name: 'Datasets', href: '/datasets', icon: CircleStackIcon},
    {name: 'Settings', href: '/settings', icon: Cog6ToothIcon},
    {name: 'Docs', href: '/docs', icon: BookOpenIcon},
  ];

  // Helper function to generate CSS classes for navigation items
  const getNavItemClasses = (isActive: boolean): string => {
    return classNames(
      isActive
        ? 'bg-[#EBE9E1] text-gray-900'
        : 'text-gray-600 hover:bg-[#EBE9E1] hover:text-gray-900',
      'flex items-center px-4 py-2 rounded-sm',
    );
  };

  useEffect(() => {
    const fetchUserTeam = async () => {
      const userTeam = await getUserTeam();

      if (session && userTeam) {
        const userInTeam = userTeam.find(
          (member: {email: string}) => member.email === session.user.email,
        );
        setSuperblocksIntegrationEnabled(userInTeam.superblocksIntegration);
      }
    };

    void fetchUserTeam();
  }, [session]);

  return (
    <div className="w-50 flex h-screen flex-col border-r border-gray-300 bg-[#F9F7F3]">
      {/* Logo and app name */}
      <div className="flex items-center space-x-2 p-4 pb-0">
        <img
          src="/logotype.png"
          alt="Narrow AI logo"
          className="my-4 ml-2 mt-5 h-4"
        />
      </div>

      {/* Main navigation */}
      <nav className="flex-1 overflow-y-auto">
        <ul className="sidebar-text space-y-1 p-2">
          {navigation.map(item => (
            <li key={item.name}>
              <Link
                onClick={e => {
                  if (item.name === 'Settings') {
                    e.preventDefault();
                    userNavigation[0].onClick();
                  }
                }}
                to={item.href}
                className={getNavItemClasses(
                  location.pathname.includes(item.href),
                )}
              >
                <item.icon
                  className="mr-3 h-5 w-5 text-[#CEBFA3]"
                  aria-hidden="true"
                />
                {item.name}
              </Link>
            </li>
          ))}
          {superblocksIntegrationEnabled && (
            <li>
              <a
                href="/agentic"
                className={getNavItemClasses(
                  location.pathname.includes('/agentic'),
                )}
              >
                <WrenchScrewdriverIcon
                  className="mr-3 h-5 w-5 text-[#CEBFA3]"
                  aria-hidden="true"
                />
                Agentic Automation
              </a>
            </li>
          )}
        </ul>
      </nav>

      {/* Getting Started Card */}
      {GettingStartedCard}

      {/* User profile section */}
      {session && !isLoading && (
        <div className="border-t border-gray-300 p-2">
          <Menu as="div">
            <Menu.Button
              className="flex w-full items-center rounded-sm px-2 py-2 text-sm font-medium text-gray-700 hover:bg-[#EBE9E1]"
              onClick={e => {
                e.preventDefault();
                userNavigation[0].onClick();
              }}
            >
              {/* User avatar */}
              <div className="mr-2 flex h-8 w-8 items-center justify-center rounded-sm bg-[#D2CFBC]">
                <span className="font-semibold text-gray-800">
                  {session.user.user_metadata.full_name
                    .split(' ')
                    .map((n: string) => n[0])
                    .join('')}
                </span>
              </div>
              {/* User info */}
              <div className="flex flex-col items-start overflow-hidden text-left">
                <span className="w-full truncate">
                  {session.user.user_metadata.full_name}
                </span>
                <span className="w-full truncate text-xs text-gray-500">
                  {session.user.email}
                </span>
              </div>
            </Menu.Button>
          </Menu>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
