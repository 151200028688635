import {Tooltip, TooltipPlacement} from '@nextui-org/react';
import isNumber from 'lodash/isNumber';
import React, {FC} from 'react';

import {Criterion} from '../types/Criterion';
import {scoreToColor} from '../utils/scoreToColor';
import {to100pointScale} from '../utils/string';

export interface ReasoningTooltipProps {
  criteria: Criterion;
  score: number | string;
  reasoning: string;
  placement?: TooltipPlacement;
  children: React.ReactNode;
}

export const ReasoningTooltip: FC<ReasoningTooltipProps> = ({
  criteria,
  score,
  reasoning,
  placement = 'bottom-start',
  children,
}) => {
  const parsedReasoning = reasoning.replace(/\b\d+(\.\d+)?\b/g, match => {
    const result = to100pointScale(match);

    if (isNumber(result)) {
      return result.toString();
    }

    return '';
  });

  return (
    <Tooltip
      placement={placement}
      radius="none"
      style={{boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.1)'}}
      content={
        <div className="flex max-w-[50vw] flex-col p-3">
          <p className="mb-2 text-sm">• {criteria.assessment}</p>
          <div className="flex items-center justify-start space-x-2">
            <span
              className="mr-2 text-sm"
              style={{color: scoreToColor(Number(score))}}
            >
              {to100pointScale(score)}
            </span>
            <p className="text-sm">{parsedReasoning}</p>
          </div>
        </div>
      }
    >
      {children}
    </Tooltip>
  );
};
