import {XMarkIcon} from '@heroicons/react/20/solid';
import {ArrowPathIcon} from '@heroicons/react/24/outline';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Spinner,
  Textarea,
} from '@nextui-org/react';
import React, {useMemo, useState} from 'react';

import {generateCriterion} from '../PromptServerClient';
import {useNotifications} from '../notifications';
import {Criterion} from '../types/Criterion';
import {ColorMap} from '../utils/scoreToColor';
import {parseScoreDescription} from '../utils/scoringGuidelines';

export interface AddCriteriaModalProps {
  isOpen: boolean;
  onClose: () => void;
  addCriteria: (criterion: Criterion) => void;
  promptData: {basePrompt: string; examples: Array<string>[]};
}

enum ActionButtonState {
  GENERATE,
  ADD,
  SPINNER,
}

export const AddCriteriaModal: React.FC<AddCriteriaModalProps> = ({
  isOpen,
  onClose,
  addCriteria,
  promptData,
}) => {
  const [actionButtonState, setActionButtonState] = useState(
    ActionButtonState.GENERATE,
  );
  const [actionButtonActive, setActionButtonActive] = useState(true);
  const [criterion, setCriterion] = useState<Criterion | null>(null);
  const [description, setDescription] = useState('');

  const {showError} = useNotifications();

  const criterionScoreDescription = useMemo(() => {
    if (!criterion) {
      return null;
    }

    return parseScoreDescription(criterion.scoring_guidelines);
  }, [criterion]);

  const formattedAssessment = useMemo(() => {
    if (!criterion) {
      return null;
    }

    const splitAssessment = criterion.assessment
      .replace(/\\[nrtbfv"'\\]/g, '')
      .split('- ')
      .filter(item => item.trim());

    if (splitAssessment.length === 1) {
      return (
        <span className="my-3 text-xs text-[#11181C]">
          {splitAssessment[0]}
        </span>
      );
    } else {
      return (
        <ul className="my-3 list-inside list-disc space-y-3">
          {splitAssessment.map((item, index) => (
            <li key={index} className="text-xs text-[#11181C]">
              {item.trim()}
            </li>
          ))}
        </ul>
      );
    }
  }, [criterion]);

  const actionButtonContent = useMemo(() => {
    switch (actionButtonState) {
      case ActionButtonState.GENERATE:
        return <span>Generate Criteria</span>;
      case ActionButtonState.ADD:
        return <span>Add Criteria</span>;
      case ActionButtonState.SPINNER:
        return <Spinner color="default" size="sm" />;
    }
  }, [actionButtonState]);

  const generate = async () => {
    try {
      setCriterion(null);
      setActionButtonState(ActionButtonState.SPINNER);
      setActionButtonActive(false);

      const response = await generateCriterion(description, promptData);

      setCriterion(response);

      setActionButtonActive(true);
      setActionButtonState(ActionButtonState.ADD);
    } catch (e) {
      showError('Failed to generate criteria');
      console.error(e);
    }
  };

  const handleActionButtonClick = async () => {
    if (actionButtonState === ActionButtonState.GENERATE) {
      await generate();
    }
    if (actionButtonState === ActionButtonState.ADD) {
      addCriteria(criterion!);
      setCriterion(null);
      setDescription('');
      setActionButtonActive(true);
      setActionButtonState(ActionButtonState.GENERATE);
      onClose();
    }
  };

  return (
    <Modal
      size="3xl"
      isOpen={isOpen}
      onClose={onClose}
      isDismissable={false}
      hideCloseButton={true}
      radius="none"
      className="max-h-[90vh] overflow-y-auto"
    >
      <ModalContent>
        <ModalHeader className="flex flex-row items-center justify-between bg-[#F9FAFB]">
          <h3 className="font-roboto text-sm font-medium text-[#111827]">
            Add Criteria
          </h3>
          <Button
            isIconOnly={true}
            radius="full"
            className="bg-[#F9FAFB]"
            onClick={() => {
              setCriterion(null);
              setDescription('');
              setActionButtonActive(true);
              setActionButtonState(ActionButtonState.GENERATE);
              onClose();
            }}
          >
            <XMarkIcon className="w-5" />
          </Button>
        </ModalHeader>
        <ModalBody className="flex flex-col items-stretch justify-between px-8 py-6">
          <div className="font-chivo mb-3 bg-[#F2F3FC] p-3 text-xs text-[#374151]">
            <span className="font-semibold">Tip</span>
            <span className="font-medium">
              : Describe your criteria in clear, straightforward language for
              the best results
            </span>
          </div>
          <h3 className="font-chivo text-xs font-medium text-[#374151]">
            Criteria Description
          </h3>
          <Textarea
            variant="bordered"
            radius="none"
            maxRows={20}
            minRows={3}
            className="flex-grow"
            classNames={{
              input: 'text-sm font-roboto text-[#374151]',
            }}
            size="lg"
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
          {criterion && criterionScoreDescription && (
            <>
              <Button
                className="self-center bg-white"
                onClick={generate}
                startContent={
                  <ArrowPathIcon className="h-5 w-5 text-[#6B7280]" />
                }
              >
                <span className="font-roboto text-sm font-medium text-[#4B5563]">
                  Regenerate
                </span>
              </Button>
              <h3 className="font-chivo mt-4 text-xs font-medium text-[#374151]">
                Confirm Generated Criteria
              </h3>
              <div className="bg-[#F2F3FC] p-6">
                <div className="flex flex-col items-start justify-between">
                  <h3 className="font-roboto mb-1.5 text-sm font-semibold text-[#374151]">
                    {criterion.criterion_name}
                  </h3>
                  {formattedAssessment}
                </div>
                <div className="grid gap-y-3">
                  {criterionScoreDescription.map(
                    ({scores, description}, index) => (
                      <div
                        key={index}
                        className="grid grid-cols-[10px_3rem_auto] items-center gap-x-2"
                      >
                        <span
                          className="rounded-full"
                          style={{
                            backgroundColor: ColorMap[scores],
                            width: '10px',
                            height: '10px',
                          }}
                        />
                        <span className="font-roboto whitespace-nowrap text-left text-xs font-medium text-[#6B7280]">
                          {scores}
                        </span>
                        <span className="text-left text-xs font-normal text-[#6B7280]">
                          {description}
                        </span>
                      </div>
                    ),
                  )}
                </div>
              </div>
            </>
          )}
          <Button
            radius="none"
            className="mb-3 mt-6 self-center bg-[#485ED5] p-6 px-8 text-[#F9FAFB]"
            onClick={handleActionButtonClick}
            disabled={!actionButtonActive}
          >
            {actionButtonContent}
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
