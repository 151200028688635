// Import necessary dependencies
import {Session} from '@supabase/supabase-js';
import {create} from 'zustand';

import {ModelConfig} from './types/ModelConfig';

// Define the shape of our state
type State = {
  openAiKey: string;
  modelConfig: ModelConfig;
  featureFlags: object;
  session: Session | null;
  setModelConfig: (config: object) => void;
  setFeatureFlags: (flags: object) => void;
  setOpenAiKey: (key: string) => void;
  setOpenWithStorageAiKey: (key: string) => void;
  isOpenAiKeyPresentInStorage: () => boolean;
  setSession: (session: Session | null) => void;
};

export enum StorageKeys {
  CODETHREAD_MODEL_CONFIG = 'codethread-model-config',
  CODETHREAD_FEATURE_FLAGS = 'codethread-feature-flags',
  CODETHREAD_OPENAI_KEY = 'codethread-openaikey',
  CODETHREAD_SESSION = 'codethread-session',
  СODETHREAD_USERID = 'codethread-userid',
  SB_RJEMEXDHTJUVFMLNXXCO_AUTH_TOKEN = 'sb-rjemexdhtjuvfmlnxxco-auth-token',
  FS_LUA = '_fs_lua',
  EMAIL = 'email',
  NAME = 'name',
  FS_SWAN_SONG = '_fs_swan_song',
  MAGICPROMPT_GENERATORS = 'magicprompt-generators',
  FS_UID = '_fs_uid',
  MAGICPROMPT_PROMPTS = 'magicprompt-prompts',
  GETTINGSTARTEDTASKS = 'gettingStartedTasks',
}

// Create our store using Zustand
const useStore = create<State>(set => ({
  // Initialize state with values from local storage or defaults
  openAiKey: getOpenAiKey(),
  modelConfig: getModelConfig(),
  featureFlags: getFeatureFlags(),
  session: getSession(),

  // Action to update model configuration
  setModelConfig: (config: any) => {
    set((state: any) => ({modelConfig: config}));
    localStorage.setItem(
      StorageKeys.CODETHREAD_MODEL_CONFIG,
      JSON.stringify(config),
    );
  },

  // Action to update feature flags
  setFeatureFlags: (flags: any) => {
    set((state: any) => ({featureFlags: flags}));
    localStorage.setItem(
      StorageKeys.CODETHREAD_FEATURE_FLAGS,
      JSON.stringify(flags),
    );
  },

  // Action to set OpenAI key (without storing in local storage)
  setOpenAiKey: (key: string) => {
    set(() => ({openAiKey: key}));
  },

  // Action to set OpenAI key and store in local storage
  setOpenWithStorageAiKey: (key: string) => {
    set(() => ({openAiKey: key}));
    localStorage.setItem(StorageKeys.CODETHREAD_OPENAI_KEY, key);
  },

  // Check if OpenAI key is present in local storage
  isOpenAiKeyPresentInStorage: () => {
    return !!localStorage.getItem(StorageKeys.CODETHREAD_OPENAI_KEY);
  },

  // Action to set session and store in local storage
  setSession: (session: Session | null) => {
    set(() => ({session: session}));
    localStorage.setItem(
      StorageKeys.CODETHREAD_SESSION,
      JSON.stringify(session),
    );
  },
}));

// Helper function to get OpenAI key from local storage
function getOpenAiKey() {
  const key = localStorage.getItem(StorageKeys.CODETHREAD_OPENAI_KEY) || '';
  return key;
}

// Helper function to get model configuration from local storage or use default
function getModelConfig() {
  const defaultConfig = {
    baseModel: 'gpt-4-1106-preview',
    jsonOutput: false,
  } as ModelConfig;
  const modelConfig = localStorage.getItem(StorageKeys.CODETHREAD_MODEL_CONFIG);
  return modelConfig ? (JSON.parse(modelConfig) as ModelConfig) : defaultConfig;
}

// Helper function to get feature flags from local storage or use default
function getFeatureFlags() {
  const defaultFlags = {
    SHOULD_USE_JSON_SCHEMA: true,
  };
  const featureFlags = localStorage.getItem(
    StorageKeys.CODETHREAD_FEATURE_FLAGS,
  );
  return featureFlags ? JSON.parse(featureFlags) : defaultFlags;
}

// Helper function to get session from local storage
function getSession() {
  const session = localStorage.getItem(StorageKeys.CODETHREAD_SESSION);
  return session ? JSON.parse(session) : null;
}

// Export the store for use in other components
export default useStore;
