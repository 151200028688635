import {
  RocketLaunchIcon,
  PlusIcon,
  WrenchIcon,
  BookOpenIcon,
  HandThumbUpIcon,
  ExclamationTriangleIcon,
  StopIcon,
} from '@heroicons/react/20/solid';
import {
  EllipsisVerticalIcon,
  ExclamationCircleIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline';
import {
  Select,
  SelectItem,
  Link,
  Card,
  CardBody,
  Dropdown,
  DropdownTrigger,
  Button,
  DropdownMenu,
  DropdownItem,
} from '@nextui-org/react';
import React, {useState, useEffect, useCallback, useRef} from 'react';

import {startSSE, registerAgent} from '../PromptServerClient';
import {addTask, updateTask} from '../actions/task';
import {addEvent} from '../actions/timelineEvent';
import CriteriaCard from '../components/CriteriaCard';
import DisplayCard from '../components/DisplayCard';
import ImportDatasetModal from '../components/ImportDatasetModal';
import {ImportFromJsonFileModal} from '../components/ImportFromJsonFileModal';
import ModeSwitch from '../components/ModeSwitch';
import CompletionsModal from '../components/PromptTrainingCompletionsModal';
import PromptMessage from '../components/PromptTrainingMessage';
import TestInput from '../components/PromptTrainingTestInput';
import examples from '../examples/examples';
import {ModelMetadata, useModelsList} from '../modelsList';
import {useNotifications} from '../notifications';
import {Criterion} from '../types/Criterion';
import {DatasetRow} from '../types/DatasetRow';
import {ModelConfig} from '../types/ModelConfig';
import {PromptNode} from '../types/PromptNode';
import {Task} from '../types/Task';
import {parseNunjucksTemplate} from '../utils/nunjucks';
import {prettyUuid} from '../utils/string';

// Data

interface UserSession {
  access_token: string;
  user: {
    id: string;
  };
}

// the state of a prompt agent, which manages the prompt optimization process
interface PromptAgent {
  isRunning: boolean;
  isCompleted: boolean;
}

const createInitialJsonSchema = (topLevelVariables: any[]) => {
  const properties = topLevelVariables.reduce((acc, variable) => {
    acc[variable] = {type: 'any'}; // Set type to 'any' initially
    return acc;
  }, {});

  return {
    type: 'object',
    properties: properties,
  };
};

const hashTemplateVariables = (templateVariables: any[]) => {
  return JSON.stringify(templateVariables);
};

const convertTaskExamplesToEvalSet = (
  examples: {input: any; target: any}[],
) => {
  return examples.map(({input, target}) => [input, target]);
};

const transformLogsToTasks = (logs: any): any => {
  return logs.map((log: any) => ({
    input: {...log.input_variables},
    target: log.responses[0].body.choices[0].message.content,
  }));
};

export default function PromptTrainingPage({
  modelConfig,
  session,
  onSuccess,
  scrollToBottom,
}: {
  modelConfig: ModelConfig;
  session: UserSession;
  onSuccess: any;
  scrollToBottom: () => void;
}) {
  // Template variable caching for debouncing template variable requests:
  const [templateVariableCache, setTemplateVariableCache] = useState<{
    [key: string]: any;
  }>({});
  const [templateVariables, setTemplateVariables] = useState<any[]>([]);
  // Store the terminationThreshold
  const [terminationThreshold, setTerminationThreshold] = useState<any | null>(
    null,
  );
  // Store the TaskName
  const [taskName, setTaskName] = useState<string | null>(null);
  // Store the Criteria
  const [criteria, setCriteria] = useState<Criterion[] | null>(null);
  // Criteria used to evaluate the last prompt
  const [resultCriteria, setResultCriteria] = useState<Criterion[] | null>(
    null,
  );
  // flag: criteria were modified
  const [criteriaModified, setCriteriaModified] = useState<boolean>(false);
  // Store new criteria names
  const [newCriteriaNames, setNewCriteriaNames] = useState<string[]>([]);
  // Completion list to determine if we are in completion list view, and what to render:
  const [currentCompletions, setCurrentCompletions] = useState<any>(null);
  // Eventsource for backend SSE:
  const [eventSource, setEventSource] = useState<any>(null);
  // Timeline for activity feed:
  const [eventTimeline, setEventTimeline] = useState<any[]>([]);
  // List of current prompt nodes to display:
  const [promptNodes, setPromptNodes] = useState<PromptNode[]>([]);
  // Prompt model of the current prompt:
  const [promptObject, setPromptObject] = useState<
    Record<string, Array<Record<string, string>>>
  >({
    messages: [
      {
        role: 'system',
        content: '',
      },
    ],
    examples: [
      {
        input: '',
        target: '',
      },
    ],
  });

  const [trainingRuns, setTrainingRuns] = useState<any | null>([]);

  const [initialModel, setInitialModel] = useState(null);
  const [selectedTrainingModel, setSelectedTrainingModel] = useState(
    modelConfig.baseModel,
  );
  const [initialCostPer1000, setInitialCostPer1000] = useState<any | null>(
    null,
  );

  const [avgInputTokens, setAvgInputTokens] = useState<number | null>(null);
  const [avgOutputTokens, setAvgOutputTokens] = useState<number | null>(null);
  const [comparisonModels, setComparisonModels] = useState<Record<
    string,
    ModelMetadata
  > | null>(null);

  // Current task being worked on (based on BBH task model), contains test data to train
  // the prompt on:
  const [task, setTask] = useState<any>({
    examples: [
      {
        input: '',
        target: '',
      },
    ],
  });

  const [importModalOpen, setImportModalOpen] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const {get: getModelList, getTrialModels} = useModelsList();
  const modelOptions = getTrialModels();

  const {showError, showSuccess, showWarning} = useNotifications();
  const containerRef = useRef<HTMLDivElement>(null);
  const [fileName, setFileName] = useState<string>('');
  const [importJsonData, setImportJsonData] = useState<Task | null>(null);
  const [importJsonModalOpen, setImportJsonModalOpen] = useState(false);

  const scrollDown = () => {
    scrollToBottom();
  };

  const [isStopped, setIsStopped] = useState(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files![0];
    setFileName(file.name);
    if (file) {
      const reader = new FileReader();
      reader.onload = e => {
        const jsonContent = e.target!.result as string;

        try {
          const json = JSON.parse(jsonContent) as {
            task_prefix?: string;
            messages: Array<{content: string}>;
            examples: Array<{
              input: string;
              target?: string;
              output?: string;
              target_scores?: Record<string, string>;
            }>;
          };
          console.log('Importing and sampling JSON:', json);

          // Prompt imports:
          if (json.messages) {
            onImport(json);
            return;
          }

          // Importing examples from jsonl fine tune format:
          if (
            Array.isArray(json) &&
            json.length > 0 &&
            json[0].prompt &&
            json[0].completion
          ) {
            json.examples = json.map(example => {
              return {
                input: example.prompt,
                output: example.completion,
              };
            });
          }

          // Importing examples from BBH task JSON format:
          if (json.examples && Array.isArray(json.examples)) {
            // // Ask user if they want to import entire dataset
            // if (json.examples.length > 5) {
            //   const importAll = confirm(
            //     `Do you want to import all ${json.examples.length} examples? Press OK for Yes, Cancel for No (by default we import only 5 examples).`,
            //   );
            //
            //   if (!importAll) {
            //     // Import only 5 random examples
            //     json.examples = json.examples
            //       .sort(() => Math.random() - 0.5)
            //       .slice(0, 5);
            //   }
            // }

            if (json.task_prefix) {
              json.examples = json.examples.map(example => {
                if (example.target_scores) {
                  return {
                    ...example,
                    input:
                      json.task_prefix +
                      example.input +
                      '\n\n' +
                      'Options:' +
                      '\n' +
                      Object.keys(example.target_scores)
                        .map(key => key)
                        .join('\n'),
                  };
                }
                return {
                  ...example,
                  input: json.task_prefix + example.input,
                };
              });
            }
          }
          // onImport(json as unknown as Task);
          // if imported examples do not match current prompt inputs, show modal for mapping, else - import
          if (
            promptObject.messages[0].content.length > 0 &&
            (!(
              Object.keys(json.examples[0].input).length ===
                templateVariables.length &&
              Object.keys(json.examples[0].input).every(key =>
                templateVariables.includes(key),
              )
            ) ||
              json.examples.length > 5)
          ) {
            setImportJsonData(json as Task);
            setImportJsonModalOpen(true);
          } else {
            onImport(json as Task);
          }
        } catch (error) {
          showError('Error parsing JSON. Please check the file format.');
          console.error('Error parsing JSON:', error);
        }
      };
      reader.readAsText(file);
    }
  };

  const handleJsonImportClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const exportToJson = () => {
    const {examples} = promptObject;
    if (!examples.length) {
      showWarning('No examples to export');
      console.error('No examples to export');
      return;
    }

    const timestamp = new Date().getTime();
    const json = {
      name: `Exported Narrow AI Task JSON - ${timestamp}`,
      description:
        'Task JSON exported from Narrow AI Automated Prompt Engineer tool',
      examples: examples.map(({input, target}) => ({input, target})),
    };

    let jsonString;
    if (promptObject.messages[0]?.content) {
      const jsonWithBasePrompt = {
        ...json,
        basePrompt: promptObject.messages[0].content,
      };
      jsonString = JSON.stringify(jsonWithBasePrompt, null, 2);
    } else {
      jsonString = JSON.stringify(json, null, 2);
    }

    const blob = new Blob([jsonString], {type: 'application/json'});
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = `APE-task-export-${timestamp}.json`;
    link.click();

    URL.revokeObjectURL(url);
  };

  const getSavedPrompt = useCallback(() => {
    const storedData: any = localStorage.getItem('trainingData');
    if (storedData) {
      const data = JSON.parse(storedData);
      const initialModel = data.logs?.[0]?.responses?.[0]?.model;
      if (initialModel) {
        setInitialModel(initialModel);
      }

      setPromptObject({
        messages: [
          {
            role: 'system',
            content: data.task,
          },
        ],
        examples: data.examples
          ? data.examples
          : transformLogsToTasks(data.logs),
      });

      // Calculate average cost per 1000 tokens and average input/output tokens
      if (data.logs && data.logs.length > 0) {
        const totalInputTokens = data.logs.reduce(
          (sum: number, log: any) =>
            sum + log.responses[0].body.usage.prompt_tokens,
          0,
        );
        const totalOutputTokens = data.logs.reduce(
          (sum: number, log: any) =>
            sum + log.responses[0].body.usage.completion_tokens,
          0,
        );
        const avgInputTokens = Math.ceil(totalInputTokens / data.logs.length);
        const avgOutputTokens = Math.ceil(totalOutputTokens / data.logs.length);

        setAvgInputTokens(avgInputTokens);
        setAvgOutputTokens(avgOutputTokens);
      }

      localStorage.removeItem('trainingData');
    }
  }, []);

  useEffect(() => {
    if (initialModel && avgInputTokens && avgOutputTokens) {
      const fetchModelsListWithComparison = async () => {
        const modelsData = await getModelList({
          modelName: initialModel,
          inputTokens: avgInputTokens,
          outputTokens: avgOutputTokens,
        });

        setComparisonModels(modelsData);

        const initialModelData =
          modelsData[initialModel] || modelsData['gpt-4-turbo'];

        if (initialModelData) {
          setInitialCostPer1000(Number(initialModelData.taskCost.per1000Tasks));
        }
      };

      fetchModelsListWithComparison();
    }
  }, [initialModel, avgInputTokens, avgOutputTokens]);

  useEffect(() => {
    getSavedPrompt();
  }, [getSavedPrompt]);

  useEffect(() => {
    if (promptObject.messages[0].content) {
      fetchJsonSchema();
    }
  }, [promptObject.messages[0].content]);

  const [promptAgent, setPromptAgent] = useState<PromptAgent | null>(null);

  const handleDatasetImport = (exampleRows: DatasetRow[]) => {
    const mapped = exampleRows.map(row => {
      return {
        input: row.input,
        target: row.expected_output,
      };
    });

    console.log(mapped);
    // @ts-expect-error
    setPromptObject(prev => {
      return {
        ...prev,
        examples: [
          ...mapped,
          ...prev.examples.filter(example =>
            Object.values(example).every(Boolean),
          ),
        ],
      };
    });
  };

  const onImport = (json: Task) => {
    setPromptObject((prev: any) => {
      const updatedObject = {
        ...prev,
        examples: json.examples,
      };

      if (json.basePrompt) {
        updatedObject.messages = [
          {
            role: 'system',
            content: json.basePrompt,
          },
        ];
      }

      return updatedObject;
    });
  };

  const stopMigrateProcess = () => {
    if (eventSource) {
      eventSource.close();
    }

    setPromptAgent({isCompleted: false, isRunning: false});
    showSuccess('Prompt optimization process stopped');
    setIsStopped(true);
  };

  // Set up template variable fetching from backend
  const templateVariablesRef = useRef<any[]>(templateVariables);
  // Clear the ref when component unmounts
  useEffect(() => {
    return () => {
      templateVariablesRef.current = [];
    };
  }, []);

  // Store the initial loading template state to avoid rendering warning message
  // before loading:
  const [hasLoadedTemplateVariables, setHasLoadedTemplateVariables] =
    useState(false);

  // Parse template variables if the prompt changes:
  const handlePromptChange = () => {
    if (promptObject.messages[0].content.length) {
      const parsedTemplateVariables = parseNunjucksTemplate(
        promptObject.messages[0].content,
      );
      if (parsedTemplateVariables.length > 0) {
        // Check if the parsed variables are different from the current ones
        if (
          JSON.stringify(parsedTemplateVariables) !==
          JSON.stringify(templateVariablesRef.current)
        ) {
          setTemplateVariables(parsedTemplateVariables);
          templateVariablesRef.current = parsedTemplateVariables;

          // Set the loading state to true which allows additional features upon it
          // to act:
          if (!hasLoadedTemplateVariables) {
            setHasLoadedTemplateVariables(true);
          }
        }
      }
    }
  };
  useEffect(handlePromptChange, [
    promptObject.messages[0].content,
    hasLoadedTemplateVariables,
  ]);

  useEffect(() => {
    if (newCriteriaNames && newCriteriaNames.length > 0) {
      setPromptAgent(null);
    }
  }, [newCriteriaNames]);

  // Fetch JSON Schema from backend whenever the prompt and template variables change:
  const fetchJsonSchema = () => {
    if (!promptObject.messages[0].content.length) {
      return;
    }

    // If we have template variables, then we need to fetch the JSON Schema for them:
    if (templateVariables.length) {
      const currentTemplateVariables = templateVariablesRef.current;
      const initialJsonSchema = createInitialJsonSchema(
        currentTemplateVariables,
      );
      if (
        !templateVariableCache[hashTemplateVariables(currentTemplateVariables)]
      ) {
        setTemplateVariableCache({
          [hashTemplateVariables(currentTemplateVariables)]: initialJsonSchema,
        });
      }
    } else {
      // Clear the example list if they are in template variable form:
      if (
        promptObject.examples.length > 0 &&
        typeof promptObject.examples[0].input !== 'string' &&
        hasLoadedTemplateVariables
      ) {
        const shouldDeleteTask = window.confirm(
          'Since you no longer have template variables, do you want to clear your dataset?',
        );
        if (shouldDeleteTask) {
          setTask({
            ...task,
            examples: [
              {
                input: '',
                target: '',
              },
            ],
          });
        }
      }
    }
  };
  useEffect(fetchJsonSchema, [
    promptObject.messages[0].content,
    templateVariables,
  ]);

  // Add a confirmation dialogue for the user before they leave the page (as it deactivates the PA in the backend):
  const handleBeforeUnload = useCallback(
    (event: {returnValue: string}) => {
      if (
        !promptAgent?.isCompleted &&
        (promptAgent?.isRunning || promptObject.examples.length > 1)
      ) {
        event.returnValue = 'Are you sure you want to leave?';
      }
      if (eventSource) {
        eventSource.close();
      }
    },
    [promptAgent, task, eventSource],
  );
  const registerBeforeUnload = () => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  };
  useEffect(registerBeforeUnload, [promptAgent, task, eventSource]);

  const loadTemplate = () => {
    const urlObj = new URL(window.location.href);
    const queryParams = new URLSearchParams(urlObj.search);
    const template = queryParams.get('template') as keyof typeof examples;
    if (template) {
      setPromptObject({
        messages: [
          {
            role: 'system',
            content: examples[template].basePrompt,
          },
        ],
        examples: examples[template].examples,
      });
    }
  };

  const setEvalMode = (value: string) => {
    return value === 'soft' ? setIsSoftEval(true) : setIsSoftEval(false);
  };

  useEffect(loadTemplate, []);

  const trainThisPrompt = useCallback((promptNode: PromptNode) => {
    setEventTimeline([]);
    setPromptNodes([]);
    setPromptAgent(null); // Reset before setting a new one

    console.log('Setting new prompt:', promptNode.prompt);

    setPromptAgent({
      messages: [
        {
          role: 'system',
          content: promptNode.prompt,
        },
      ],
      isCompleted: false,
      isRunning: false,
    } as PromptAgent);

    setPromptObject((prevPromptObject: Record<string, unknown>) => ({
      ...prevPromptObject,
      messages: [
        {
          role: 'system',
          content: promptNode.prompt,
        },
      ],
    }));
  }, []);

  // Disable adding of items if the last item is empty:
  const shouldDisableAddButton =
    promptObject.examples.length > 0 &&
    promptObject.examples[promptObject.examples.length - 1].input === '';

  const [isSoftEval, setIsSoftEval] = useState<boolean>(false);
  return (
    <div className="min-h-screen bg-gray-50" ref={containerRef}>
      <div className="pb-8">
        <div className="bg-white px-5 py-6 text-left shadow">
          <Link
            href="/"
            className="mb-2 text-left text-sm text-blue-600 hover:underline"
          >
            Prompts
          </Link>
          <h1 className="font-chivo mb-4 text-left text-xl">
            Prompt Optimization
          </h1>
          <div className="flex items-start justify-between">
            <div className="w-[60%]">
              <div className="mt-2">
                <div className="mb-4 flex">
                  <Select
                    disallowEmptySelection
                    radius="none"
                    label="Model"
                    value={selectedTrainingModel}
                    onChange={e => setSelectedTrainingModel(e.target.value)}
                    className="w-1/2"
                    classNames={{
                      trigger: 'min-w-[300px]',
                      value: 'truncate max-w-[calc(100%-40px)]',
                    }}
                    renderValue={value => {
                      const selectedOption = modelOptions.find(
                        option => option.value === value[0]?.props?.value,
                      );
                      return initialModel && selectedOption
                        ? initialModel === selectedOption.value
                          ? selectedOption.name
                          : `${initialModel} > ${selectedOption.name}`
                        : selectedOption
                          ? selectedOption.name
                          : 'Select a model';
                    }}
                    selectedKeys={
                      selectedTrainingModel ? [selectedTrainingModel] : []
                    }
                    isDisabled={promptAgent ? promptAgent.isRunning : false}
                  >
                    {modelOptions.map(option => {
                      let costDifference = null;
                      let colorClass = '';
                      if (
                        initialModel &&
                        initialCostPer1000 &&
                        comparisonModels &&
                        Object.keys(comparisonModels).length > 0
                      ) {
                        const {absoluteDifference, percentageDifference} =
                          comparisonModels[option.value].comparison;
                        const isNegative = absoluteDifference < 0;
                        colorClass = isNegative
                          ? 'text-green-400'
                          : 'text-red-400';
                        costDifference = `${isNegative ? '' : '+'}$${Math.abs(absoluteDifference).toFixed(2)} (${isNegative ? '' : '+'}${percentageDifference.toFixed(2)}%)`;
                      }
                      return (
                        <SelectItem
                          hideSelectedIcon
                          key={option.value}
                          value={option.value}
                          endContent={
                            costDifference && (
                              <span
                                className={`inline-block whitespace-nowrap text-xs ${colorClass}`}
                              >
                                {costDifference}
                              </span>
                            )
                          }
                        >
                          {option.name}
                        </SelectItem>
                      );
                    })}
                  </Select>
                  <div className="ml-10">
                    <p className="font-chivo mb-1 text-left">Evaluation mode</p>
                    <ModeSwitch
                      defaultValue={'hard'}
                      setEvaluationMode={setEvalMode}
                    />
                  </div>
                </div>
                <PromptMessage
                  prompt={promptObject}
                  setPrompt={setPromptObject}
                  templateVariables={templateVariables}
                />
              </div>
            </div>
            {initialCostPer1000 !== null && (
              <Card className="mt-2 w-[35%]" radius="none">
                <CardBody className="p-4">
                  <h3 className="mb-2 text-sm font-semibold">Cost Estimate</h3>
                  <p className="mb-2 text-sm">
                    This cost estimate is based on the token distribution from
                    your task logs:
                  </p>
                  <div className="flex flex-col space-y-2">
                    <div className="w-full rounded-none bg-primary-200 p-2 text-sm">
                      <div className="flex w-full justify-between text-primary-500">
                        <span className="mr-1">{initialModel}:</span>
                        <span>
                          ${initialCostPer1000?.toFixed(2) || '0.00'} per 1000
                          tasks
                        </span>
                      </div>
                    </div>
                    {selectedTrainingModel &&
                      comparisonModels &&
                      Object.keys(comparisonModels).length > 0 &&
                      selectedTrainingModel !== initialModel && (
                        <>
                          {(() => {
                            const {absoluteDifference, percentageDifference} =
                              comparisonModels[selectedTrainingModel]
                                .comparison;
                            const isNegative = absoluteDifference < 0;
                            const colorClass = isNegative
                              ? 'bg-success-100 text-success-600'
                              : 'bg-danger-100 text-danger-600';
                            return (
                              <div
                                className={`w-full text-sm ${colorClass} mt-2 rounded-none p-2`}
                              >
                                <div className="flex w-full justify-between">
                                  <span className="mr-1">
                                    Estimated{' '}
                                    {isNegative ? 'savings' : 'increase'}:
                                  </span>
                                  <span>
                                    ${Math.abs(absoluteDifference).toFixed(2)} (
                                    {isNegative ? '' : '+'}
                                    {percentageDifference.toFixed(2)}%) per 1000
                                    tasks
                                  </span>
                                </div>
                              </div>
                            );
                          })()}
                        </>
                      )}
                    <button
                      type="button"
                      disabled={promptAgent ? promptAgent.isRunning : false}
                      className="relative inline-flex items-center gap-x-1.5 rounded-none bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                      onClick={async () => {
                        setTaskName(null);
                        setCriteria(null);
                        if (!promptAgent) {
                          const registerAgentResponse = await registerAgent(
                            promptObject.messages[0].content,
                            convertTaskExamplesToEvalSet(
                              promptObject.examples,
                            ) as any,
                            {
                              ...modelConfig,
                              baseModel: selectedTrainingModel,
                            },
                            session.user.id,
                            isSoftEval,
                          );

                          scrollDown();

                          if (registerAgentResponse.clientId) {
                            setEventTimeline(() => []);
                            setPromptNodes(() => []);
                            if (eventSource) {
                              eventSource.close();
                            }
                            setTrainingRuns(prev => [
                              ...prev,
                              {
                                id: registerAgentResponse.clientId,
                                model: selectedTrainingModel,
                              },
                            ]);

                            const newEventSource = await startSSE(
                              ({
                                type,
                                node,
                                nodeList,
                                metadata,
                              }: {
                                type: string;
                                node: any;
                                nodeList: any[];
                                metadata: any;
                              }) => {
                                if (type === 'extract_criteria') {
                                  setEventTimeline(eventTimeline =>
                                    addEvent(eventTimeline, {
                                      id: node.id,
                                      content:
                                        'Extracting task name and criteria',
                                      icon: RocketLaunchIcon,
                                      iconBackground: 'bg-gray-400',
                                    }),
                                  );

                                  // Scroll to bottom once node has been added:
                                  scrollDown();
                                }

                                if (type === 'received_threshold') {
                                  // Store the termination threshold for later use
                                  setTerminationThreshold(
                                    metadata.terminationThreshold,
                                  );
                                }
                                if (type === 'received_taskname_and_criteria') {
                                  // Store the task name and criteria for later use
                                  setTaskName(metadata.taskName);
                                  setCriteria(metadata.criteria);
                                }

                                if (type === 'begin_process') {
                                  setEventTimeline(eventTimeline =>
                                    addEvent(eventTimeline, {
                                      id: node.id,
                                      content: 'Starting prompt optimization',
                                      icon: RocketLaunchIcon,
                                      iconBackground: 'bg-gray-400',
                                    }),
                                  );

                                  // Scroll to bottom once node has been added:
                                  scrollDown();
                                }
                                if (type === 'expanded_tree') {
                                  setEventTimeline(eventTimeline =>
                                    addEvent(eventTimeline, {
                                      id: node.id,
                                      content: `Creating new prompt ${prettyUuid(
                                        node.id,
                                      )}`,
                                      icon: PlusIcon,
                                      iconBackground: 'bg-gray-400',
                                    }),
                                  );
                                }
                                if (
                                  type === 'begin_process' ||
                                  type === 'end_iteration' ||
                                  type === 'end_simulation' ||
                                  type === 'end_expansion' ||
                                  type == 'extract_criteria'
                                ) {
                                  setPromptNodes(nodeList);
                                }
                                if (type === 'start_simulate') {
                                  setEventTimeline(eventTimeline =>
                                    addEvent(eventTimeline, {
                                      id: node.id + '-simulate',
                                      content: `Running prompt ${prettyUuid(node.id)}`,
                                      icon: WrenchIcon,
                                      iconBackground: 'bg-yellow-500',
                                    }),
                                  );
                                }
                                if (type === 'start_evaluation') {
                                  setEventTimeline(eventTimeline =>
                                    addEvent(eventTimeline, {
                                      id: node.id + '-evaluate',
                                      content: `Reviewing prompt ${prettyUuid(
                                        node.id,
                                      )}`,
                                      icon: WrenchIcon,
                                      iconBackground: 'bg-yellow-500',
                                    }),
                                  );
                                }
                                if (type === 'end_evaluation') {
                                  setEventTimeline(eventTimeline =>
                                    addEvent(eventTimeline, {
                                      id: node.id + '-feedback',
                                      content: `Received feedback for prompt ${prettyUuid(
                                        node.id,
                                      )}`,
                                      icon: BookOpenIcon,
                                      iconBackground: 'bg-yellow-500',
                                    }),
                                  );
                                }
                                if (type === 'end_process') {
                                  const content =
                                    node.reward === 1
                                      ? 'Optimal prompt found'
                                      : `Best prompt found with score of ${node.reward}`;
                                  setEventTimeline(eventTimeline =>
                                    addEvent(eventTimeline, {
                                      id: `${prettyUuid(node.id)}-won`,
                                      content,
                                      href: `#prompt-node-${prettyUuid(node.id)}`,
                                      target: `${prettyUuid(node.id)}`,
                                      icon: HandThumbUpIcon,
                                      iconBackground: 'bg-green-500',
                                    }),
                                  );

                                  setPromptAgent({
                                    isRunning: false,
                                    isCompleted: true,
                                  });

                                  // Update the training run with the final reward and prompt nodes
                                  setTrainingRuns((prevRuns: any) => {
                                    const updatedRuns = [...prevRuns];
                                    const lastRun =
                                      updatedRuns[updatedRuns.length - 1];
                                    if (lastRun) {
                                      lastRun.reward = node.reward;
                                      lastRun.promptNodes = promptNodes;
                                    }
                                    return updatedRuns;
                                  });

                                  if (node.reward === 1) {
                                    onSuccess();
                                  }
                                }
                                if (type === 'Error') {
                                  showError('Error encountered');
                                  setEventTimeline(eventTimeline =>
                                    addEvent(eventTimeline, {
                                      id: node.id + '-error',
                                      content: `Error encountered: ${node.message}`,
                                      icon: ExclamationTriangleIcon,
                                      iconBackground: 'bg-red-500',
                                    }),
                                  );
                                }
                              },
                              registerAgentResponse.clientId,
                            );
                            setPromptAgent({
                              isRunning: true,
                              isCompleted: false,
                            });
                            setEventSource(newEventSource);
                          }
                        } else {
                          console.log('existing prompt agent');
                          setPromptAgent({
                            isRunning: false,
                            isCompleted: false,
                          });
                        }
                      }}
                    >
                      {!promptAgent || !promptAgent.isRunning ? (
                        <RocketLaunchIcon
                          className="-ml-0.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      ) : null}

                      {'Run'}
                    </button>
                  </div>
                </CardBody>
              </Card>
            )}
          </div>
        </div>
      </div>
      <div className="pb-8">
        <div className="bg-white px-5 py-6 shadow">
          <div className="mb-4 mr-12 flex flex-row content-center items-center justify-between">
            <h2 className="font-chivo text-m mb-4 text-left">Data</h2>
            <input
              style={{display: 'none'}}
              ref={fileInputRef}
              title="Import"
              type="file"
              accept=".json"
              onClick={(e: any) => {
                //This is added here, as due to the react input API, if a user tries to import the same file again it will not trigger onChange.
                e.target.value = ''; //Reference: https://stackoverflow.com/questions/39484895/how-to-allow-input-type-file-to-select-the-same-file-in-react-component
              }}
              onChange={handleFileChange}
            />
            <div className="flex w-fit flex-row content-center items-center justify-end gap-1">
              <Dropdown radius="none">
                <DropdownTrigger>
                  <Button
                    radius="none"
                    className="ml-3 border-none bg-white"
                    isIconOnly
                  >
                    <EllipsisVerticalIcon className="h-7 w-7" />
                  </Button>
                </DropdownTrigger>
                <DropdownMenu>
                  <DropdownItem
                    key={'import dataset'}
                    className="font-chivo text-right text-sm font-medium text-[#374151]"
                    onClick={() => setImportModalOpen(true)}
                  >
                    Import Dataset
                  </DropdownItem>
                  <DropdownItem
                    key={'import json'}
                    className="font-chivo text-right text-sm font-medium text-[#374151]"
                    onClick={handleJsonImportClick}
                  >
                    Import JSON
                  </DropdownItem>
                  <DropdownItem
                    key={'export json'}
                    className="font-chivo text-right text-sm font-medium text-[#374151]"
                    onClick={exportToJson}
                  >
                    Export JSON
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <TestInput
            task={promptObject}
            setTask={setPromptObject}
            updateTask={updateTask}
            currentJSONSchema={
              templateVariableCache[hashTemplateVariables(templateVariables)] ||
              null
            }
            isExternal={false}
          />
          <div className="mt-6 flex items-center justify-start gap-x-2">
            <button
              disabled={shouldDisableAddButton}
              type="button"
              className={`relative inline-flex items-center rounded-none px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10 ${
                shouldDisableAddButton
                  ? 'bg-gray-50 text-gray-500 hover:border-none'
                  : 'bg-white text-gray-900 hover:bg-gray-50'
              }`}
              title={
                shouldDisableAddButton
                  ? 'Add data to last item before adding a new row'
                  : ''
              }
              onClick={() =>
                setPromptObject((promptObject: any) => addTask(promptObject))
              }
            >
              <PlusCircleIcon
                className="-ml-0.5 mr-2 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              Add New
            </button>
          </div>
        </div>
      </div>
      {taskName && criteria && (
        <CriteriaCard
          taskInfo={{taskName, criteria}}
          addCriteria={(criterion: Criterion) => {
            setCriteriaModified(true);
            setNewCriteriaNames([
              ...newCriteriaNames,
              criterion.criterion_name,
            ]);
            setCriteria([...criteria, criterion]);
            showSuccess('Criteria added');
          }}
          newCriteriaNames={newCriteriaNames}
          removeCriteria={(criterion: Criterion) => {
            setCriteriaModified(true);
            setNewCriteriaNames(
              newCriteriaNames.filter(
                name => name !== criterion.criterion_name,
              ),
            );
            setCriteria(criteria.filter(c => c !== criterion));
            showSuccess('Criteria removed');
          }}
          promptData={{
            basePrompt: promptObject.messages[0].content,
            examples: convertTaskExamplesToEvalSet(promptObject.examples),
          }}
        />
      )}
      {newCriteriaNames && newCriteriaNames.length > 0 && (
        <div className="mb-6 flex h-20 w-full flex-row items-center justify-center bg-[#EBA70714]">
          <h2 className="inline-flex flex-row items-center justify-between">
            <ExclamationCircleIcon className="h-5 w-5 text-[#EBA707]" />
            <span className="font-roboto ml-3 text-sm font-normal text-[#374151]">
              Your criteria has changed. Run again to see updated results.
            </span>
          </h2>
        </div>
      )}
      <div className="pb-6">
        <button
          type="button"
          className={`relative inline-flex items-center gap-x-1.5 rounded-none ${promptAgent?.isRunning ? 'bg-red-600 ring-1 ring-inset ring-red-600 hover:bg-red-600' : 'bg-[#485ED5] ring-1 ring-inset ring-[#485ED5] hover:bg-[#485ED5]'} px-12 py-5 text-sm font-semibold text-white hover:outline-0 focus:z-10 focus:outline-0`}
          onClick={async () => {
            if (promptAgent && promptAgent.isRunning) {
              stopMigrateProcess();
              return;
            }
            if (criteriaModified) {
              setNewCriteriaNames([]);
            } else {
              setTaskName(null);
              setCriteria(null);
            }
            setResultCriteria(criteria);
            setIsStopped(false);
            const registerAgentResponse = await registerAgent(
              promptObject.messages[0].content,
              convertTaskExamplesToEvalSet(promptObject.examples) as any,
              {...modelConfig, baseModel: selectedTrainingModel},
              session.user.id,
              isSoftEval,
              taskName && criteria ? {taskName, criteria} : undefined,
            );

            if (registerAgentResponse.clientId) {
              setEventTimeline(() => []);
              setPromptNodes(() => []);
              if (eventSource) {
                eventSource.close();
              }
              setTrainingRuns(prev => [
                ...prev,
                {
                  id: registerAgentResponse.clientId,
                  model: selectedTrainingModel,
                },
              ]);

              const newEventSource = await startSSE(
                ({
                  type,
                  node,
                  nodeList,
                  metadata,
                }: {
                  type: string;
                  node: any;
                  nodeList: any[];
                  metadata: any;
                }) => {
                  if (type === 'extract_criteria') {
                    setEventTimeline(eventTimeline =>
                      addEvent(eventTimeline, {
                        id: node.id,
                        content: 'Extracting task name and criteria',
                        icon: RocketLaunchIcon,
                        iconBackground: 'bg-gray-400',
                      }),
                    );
                    scrollDown();
                  }

                  if (type === 'received_threshold') {
                    // Store the termination threshold for later use
                    setTerminationThreshold(metadata.terminationThreshold);
                  }
                  if (type === 'received_taskname_and_criteria') {
                    // Store the task name and criteria for later use
                    setTaskName(metadata.taskName);
                    setCriteria(metadata.criteria);
                    setResultCriteria(metadata.criteria);
                    scrollDown();
                  }

                  if (type === 'begin_process') {
                    setEventTimeline(eventTimeline =>
                      addEvent(eventTimeline, {
                        id: node.id,
                        content: 'Starting prompt optimization',
                        icon: RocketLaunchIcon,
                        iconBackground: 'bg-gray-400',
                      }),
                    );

                    scrollDown();
                  }
                  if (type === 'expanded_tree') {
                    setEventTimeline(eventTimeline =>
                      addEvent(eventTimeline, {
                        id: node.id,
                        content: `Creating new prompt ${prettyUuid(node.id)}`,
                        icon: PlusIcon,
                        iconBackground: 'bg-gray-400',
                      }),
                    );
                  }
                  if (
                    type === 'begin_process' ||
                    type === 'end_iteration' ||
                    type === 'end_simulation' ||
                    type === 'end_expansion' ||
                    type == 'extract_criteria'
                  ) {
                    setPromptNodes(nodeList);
                    scrollDown();
                  }
                  if (type === 'start_simulate') {
                    setEventTimeline(eventTimeline =>
                      addEvent(eventTimeline, {
                        id: node.id + '-simulate',
                        content: `Running prompt ${prettyUuid(node.id)}`,
                        icon: WrenchIcon,
                        iconBackground: 'bg-yellow-500',
                      }),
                    );
                  }
                  if (type === 'start_evaluation') {
                    setEventTimeline(eventTimeline =>
                      addEvent(eventTimeline, {
                        id: node.id + '-evaluate',
                        content: `Reviewing prompt ${prettyUuid(node.id)}`,
                        icon: WrenchIcon,
                        iconBackground: 'bg-yellow-500',
                      }),
                    );
                  }
                  if (type === 'end_evaluation') {
                    setEventTimeline(eventTimeline =>
                      addEvent(eventTimeline, {
                        id: node.id + '-feedback',
                        content: `Received feedback for prompt ${prettyUuid(
                          node.id,
                        )}`,
                        icon: BookOpenIcon,
                        iconBackground: 'bg-yellow-500',
                      }),
                    );
                  }
                  if (type === 'end_process') {
                    const content =
                      node.reward === 1
                        ? 'Optimal prompt found'
                        : `Best prompt found with score of ${node.reward}`;
                    setEventTimeline(eventTimeline =>
                      addEvent(eventTimeline, {
                        id: `${prettyUuid(node.id)}-won`,
                        content,
                        href: `#prompt-node-${prettyUuid(node.id)}`,
                        target: `${prettyUuid(node.id)}`,
                        icon: HandThumbUpIcon,
                        iconBackground: 'bg-green-500',
                      }),
                    );

                    setPromptAgent({
                      isRunning: false,
                      isCompleted: true,
                    });

                    // Update the training run with the final reward and prompt nodes
                    setTrainingRuns((prevRuns: any) => {
                      const updatedRuns = [...prevRuns];
                      const lastRun = updatedRuns[updatedRuns.length - 1];
                      if (lastRun) {
                        lastRun.reward = node.reward;
                        lastRun.promptNodes = promptNodes;
                      }
                      return updatedRuns;
                    });

                    if (node.reward === 1) {
                      onSuccess();
                    }

                    showSuccess('Optimal prompt found');
                  }
                  if (type === 'Error') {
                    showError('Error encountered');
                    setEventTimeline(eventTimeline =>
                      addEvent(eventTimeline, {
                        id: node.id + '-error',
                        content: `Error encountered: ${node.message}`,
                        icon: ExclamationTriangleIcon,
                        iconBackground: 'bg-red-500',
                      }),
                    );
                  }
                },
                registerAgentResponse.clientId,
              );
              setPromptAgent({
                isRunning: true,
                isCompleted: false,
              });
              setEventSource(newEventSource);
            }
          }}
        >
          {(!promptAgent || !promptAgent.isRunning) && (
            <>
              <RocketLaunchIcon
                className="-ml-0.5 mr-2 h-5 w-5 text-white"
                aria-hidden="true"
              />
              Run
            </>
          )}
          {promptAgent && promptAgent.isRunning && (
            <>
              <StopIcon
                className="-ml-0.5 mr-2 h-5 w-5 text-white"
                aria-hidden="true"
              />
              Stop
            </>
          )}
        </button>
      </div>
      {promptNodes.length ? (
        <div className="mb-2">
          <div className="bg-white px-5 py-6 shadow">
            <DisplayCard
              trainingRun={trainingRuns[trainingRuns.length - 1]}
              timeline={eventTimeline}
              promptNodes={promptNodes}
              terminationThreshold={terminationThreshold}
              taskInfo={
                taskName && resultCriteria
                  ? {taskName, criteria: resultCriteria}
                  : undefined
              }
              isStopped={isStopped}
              examples={promptObject.examples}
              trainThisPrompt={trainThisPrompt}
            />
          </div>
        </div>
      ) : null}
      <CompletionsModal
        examples={promptObject.examples}
        currentCompletions={currentCompletions}
        setCurrentCompletions={setCurrentCompletions}
      />
      <ImportDatasetModal
        isOpen={importModalOpen}
        onClose={() => setImportModalOpen(false)}
        onImport={handleDatasetImport}
        inputs={templateVariables}
      />
      <ImportFromJsonFileModal
        isOpen={importJsonModalOpen}
        onClose={() => setImportJsonModalOpen(false)}
        onImport={onImport}
        jsonData={importJsonData}
        fileName={fileName}
        inputs={templateVariables}
      />
    </div>
  );
}
