import {IdentificationIcon} from '@heroicons/react/24/outline';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Card,
  CardBody,
  Input,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Tabs,
  Tab,
  Snippet,
} from '@nextui-org/react';
import React, {useRef, useState, useEffect} from 'react';

import ModelSelector from './ModelSelectorDropdown';
import {
  createApiKey,
  listApiKeys,
  deleteApiKey,
  getUserTeam,
} from '../PromptServerClient';
import {useModelsList} from '../modelsList';
import useStore, {StorageKeys} from '../store';

interface SettingsModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function SettingsModal({open, setOpen}: SettingsModalProps) {
  const stateStorage = useStore.getState();
  const modelConfig = stateStorage.modelConfig as {customEval?: string};
  const setModelConfig = stateStorage.setModelConfig;

  const cancelButtonRef = useRef(null);
  const modelTypes = Object.keys(modelConfig);

  const [customEval, setCustomEval] =
    useState(`TASK: Score the quality of generated ruby comment against expected comment

Instructions:
* The param types should match the expected output
* The output should only contain a code comment, no other text or markdown formatting such as ruby code block tags
* Ignore marking down and including minor differences in the description texts, as long as they are describing the function well
* E.g: ('the parser type to use' but got 'the type of parser to use") is a very minor difference we can ignore`);
  const [isCustomEvalEnabled, setIsCustomEvalEnabled] = useState(false);
  const [shouldShowCustomEval, setShouldShowCustomEval] = useState(false);
  const [apiKeys, setApiKeys] = useState<any[]>([]);
  const [newKeyName, setNewKeyName] = useState('');
  const [newApiKey, setNewApiKey] = useState('');
  const [teamData, setTeamData] = useState<any>(null);
  const [inviteEmail, setInviteEmail] = useState('');

  const models = useModelsList();

  useEffect(() => {
    function handleKeydown(event: KeyboardEvent) {
      if (event.key === 'o' && event.shiftKey && event.metaKey) {
        setShouldShowCustomEval(prev => !prev);
      }
    }

    window.addEventListener('keydown', handleKeydown);
    return () => window.removeEventListener('keydown', handleKeydown);
  }, []);

  useEffect(() => {
    fetchApiKeys();
    fetchTeamData();
  }, []);

  const fetchApiKeys = async () => {
    try {
      const keys = await listApiKeys();
      setApiKeys(keys);
    } catch (error) {
      console.error('Failed to fetch API keys:', error);
    }
  };

  const fetchTeamData = async () => {
    try {
      const team = await getUserTeam();
      setTeamData(team);
    } catch (error) {
      console.error('Failed to fetch team data:', error);
    }
  };

  const handleCreateApiKey = async () => {
    if (newKeyName.trim() === '') {
      console.error('API key name cannot be empty');
      return;
    }
    try {
      const newKey = await createApiKey(newKeyName);
      setNewApiKey(newKey.apiKey);
      setNewKeyName('');
      fetchApiKeys();
    } catch (error) {
      console.error('Failed to create API key:', error);
    }
  };

  const handleDeleteApiKey = async (resourceName: string) => {
    try {
      await deleteApiKey(resourceName);
      fetchApiKeys();
    } catch (error) {
      console.error('Failed to delete API key:', error);
    }
  };

  const handleContinue = () => {
    const newConfig = {...modelConfig};
    if (isCustomEvalEnabled && customEval.trim() !== '') {
      newConfig.customEval = customEval;
    }
    setModelConfig(newConfig);
    setOpen(false);
  };

  const handleLogout = () => {
    Object.values(StorageKeys).forEach(key => localStorage.removeItem(key));
    useStore.setState(useStore.getInitialState());
    window.location.reload();
  };

  const handleClose = () => {
    setNewApiKey('');
    setOpen(false);
  };

  return (
    <Modal
      isOpen={open}
      onClose={handleClose}
      size="2xl"
      radius="none"
      classNames={{
        wrapper: 'items-start',
      }}
    >
      <ModalContent className="h-[550px]">
        <ModalHeader className="flex flex-col gap-1">Settings</ModalHeader>
        <ModalBody className="overflow-y-auto">
          <Tabs aria-label="Settings tabs" radius="none" disableAnimation>
            <Tab key="profile" title="Profile">
              <Card radius="none">
                <CardBody>
                  <div className="flex items-start space-x-4">
                    <div className="flex-shrink-0">
                      <div className="flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                        <IdentificationIcon
                          className="h-6 w-6 text-green-600"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                    <div className="flex-grow">
                      <Input
                        radius="none"
                        label="Name"
                        value={
                          stateStorage?.session?.user.user_metadata.full_name ||
                          ''
                        }
                        isReadOnly
                        className="mb-4"
                      />
                      <Input
                        radius="none"
                        label="Email"
                        value={stateStorage?.session?.user.email || ''}
                        isReadOnly
                        className="mb-4"
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card radius="none" className="mt-4">
                <CardBody>
                  <h4 className="mb-2 text-sm font-medium text-gray-900">
                    Team Members
                  </h4>
                  <div className="space-y-2">
                    {teamData &&
                      teamData.map((member: any) => (
                        <div
                          key={member.id}
                          className="flex items-center justify-between"
                        >
                          <div className="flex items-center">
                            <div className="mr-2 flex h-8 w-8 items-center justify-center rounded-full bg-gray-300 font-bold text-gray-600">
                              {member.email.charAt(0).toUpperCase()}
                            </div>
                            <span>{member.email}</span>
                          </div>
                        </div>
                      ))}
                  </div>
                </CardBody>
              </Card>
            </Tab>
            <Tab key="model-settings" title="Model Settings">
              <Card radius="none">
                <CardBody>
                  <h4 className="mb-2 text-sm font-medium text-gray-900">
                    Model + eval settings
                  </h4>
                  {modelTypes.map(modelType => (
                    <ModelSelector
                      key={modelType}
                      modelConfig={modelConfig}
                      setModelConfig={setModelConfig}
                      modelType={modelType}
                      modelOptions={models.getMainModels()}
                    />
                  ))}
                </CardBody>
              </Card>
            </Tab>
            <Tab key="api-keys" title="API Keys">
              <Card radius="none">
                <CardBody>
                  <div className="mb-4 flex items-end">
                    <Input
                      radius="none"
                      label="New API Key Name"
                      value={newKeyName}
                      onChange={e => setNewKeyName(e.target.value)}
                      className="mr-2 flex-grow"
                      size="sm"
                    />
                    <Button
                      radius="none"
                      color="primary"
                      onPress={handleCreateApiKey}
                      isDisabled={newKeyName.trim() === ''}
                      size="lg"
                    >
                      Create
                    </Button>
                  </div>
                  {newApiKey && (
                    <div className="mb-4 rounded bg-green-100 p-2">
                      <p className="font-medium">New API Authorization Key:</p>
                      <Snippet
                        className="break-all"
                        symbol=""
                        size="sm"
                        radius="none"
                      >
                        {'Bearer ' + newApiKey}
                      </Snippet>
                      <p className="mt-2 text-sm text-gray-600">
                        Make sure to copy this key now for use as a bearer token
                        in your Authorization request headers. You won't be able
                        to see it again!
                      </p>
                    </div>
                  )}
                  <Table aria-label="API Keys" className="w-full" radius="none">
                    <TableHeader>
                      <TableColumn>Name</TableColumn>
                      <TableColumn>Actions</TableColumn>
                    </TableHeader>
                    <TableBody>
                      {apiKeys.length === 0 ? (
                        <TableRow>
                          <TableCell>
                            No API keys found. Create one to get started!
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      ) : (
                        apiKeys.map(key => (
                          <TableRow key={key.resourceName}>
                            <TableCell>{key.resourceName}</TableCell>
                            <TableCell>
                              <Button
                                radius="none"
                                color="danger"
                                variant="light"
                                size="sm"
                                onPress={() =>
                                  handleDeleteApiKey(key.resourceName)
                                }
                              >
                                Delete
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </CardBody>
              </Card>
            </Tab>
          </Tabs>
        </ModalBody>
        <ModalFooter>
          <Button
            radius="none"
            color="danger"
            variant="light"
            onPress={handleLogout}
          >
            Sign out
          </Button>
          <Button
            radius="none"
            color="primary"
            onPress={handleContinue}
            ref={cancelButtonRef}
          >
            Continue
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
