import {XMarkIcon} from '@heroicons/react/20/solid';
import {
  Autocomplete,
  AutocompleteItem,
  Button,
  Chip,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Select,
  SelectItem,
  Spinner,
  Switch,
} from '@nextui-org/react';
import isNumber from 'lodash/isNumber';
import React, {FC, useEffect, useMemo, useState} from 'react';

import axiosInstance from '../axiosInstance';
import {Dataset} from '../types/Dataset';
import {DatasetRow} from '../types/DatasetRow';

export interface ImportDatasetModalProps {
  isOpen: boolean;
  onClose: () => void;
  onImport: (rows: DatasetRow[]) => Promise<void>;
  inputs: string[];
}

const ImportDatasetModal: FC<ImportDatasetModalProps> = ({
  isOpen,
  onClose,
  onImport,
  inputs,
}) => {
  const [datasetList, setDatasetList] = useState({
    datasets: [] as Dataset[],
    loading: false,
    filter: '',
  });
  const [selectedDataset, setSelectedDataset] = useState<Dataset>();
  const [allLines, setAllLines] = useState(false);
  const [selectedLines, setSelectedLines] = useState({
    from: 0,
    to: 1,
  });
  const [mapping, setMapping] = useState<Record<string, string>>({});
  const [isFetchingRows, setIsFetchingRows] = useState(false);

  const selectedDatasetKeys = useMemo(() => {
    if (!selectedDataset) {
      return [];
    }

    return [...selectedDataset.inputs, 'expected_output'];
  }, [selectedDataset]);

  useEffect(() => {
    if (
      !selectedDataset ||
      !selectedDatasetKeys ||
      selectedDatasetKeys.length === 0 ||
      !inputs ||
      inputs.length === 0
    ) {
      setMapping({});
    }

    if (inputs.length > 0) {
      const newMapping = [...inputs, 'expected_output'].reduce(
        (acc, input) => {
          acc[input] = selectedDatasetKeys.includes(input) ? input : '';
          return acc;
        },
        {} as Record<string, string>,
      );

      setMapping(newMapping);
    } else if (selectedDatasetKeys.length > 0) {
      setMapping({
        input: selectedDatasetKeys[0],
        expected_output: selectedDatasetKeys.includes('expected_output')
          ? 'expected_output'
          : '',
      });
    }
  }, [selectedDataset, selectedDatasetKeys, inputs]);

  useEffect(() => {
    const {filter, loading} = datasetList;

    if (loading) {
      return;
    }

    const fetchDatasets = async () => {
      try {
        const {data} = await axiosInstance.get('/datasets', {
          params: {
            search: filter,
            page: 1,
            limit: 100,
          },
        });

        setDatasetList(prevState => ({
          ...prevState,
          datasets: data.data,
          loading: false,
        }));
      } catch (e) {
        console.log('Error fetching datasets:', e);
        setDatasetList(prevState => ({
          ...prevState,
          loading: false,
        }));
      }
    };

    void fetchDatasets();
  }, [datasetList.filter]);

  useEffect(() => {
    setSelectedLines({
      from: 0,
      to: (selectedDataset && selectedDataset?.dataset_rows![0].count - 1) || 0,
    });
  }, [selectedDataset]);

  const getAndProcessRows = async () => {
    if (
      selectedDataset &&
      (allLines ||
        (isNumber(selectedLines.from) && isNumber(selectedLines.to))) &&
      Object.keys(mapping).length ===
        Object.values(mapping).filter(Boolean).length
    ) {
      try {
        const response = await axiosInstance.get(
          `/datasets/${selectedDataset.id}/rows/range`,
          {
            params: {
              start: allLines ? 0 : selectedLines.from,
              end: allLines
                ? selectedDataset.dataset_rows![0].count - 1
                : selectedLines.to,
            },
          },
        );

        const rows = response.data.data;

        const resultArray = rows.map((row: DatasetRow) => {
          if (
            Object.keys(mapping).length === 2 &&
            Object.keys(mapping).includes('input') &&
            Object.keys(mapping).includes('expected_output')
          ) {
            return {
              input: row.input[mapping.input],
              expected_output: row.expected_output,
            };
          }
          const input = {} as Record<string, string>;

          Object.keys(mapping).forEach(key => {
            const mappedKey = mapping[key];
            if (mappedKey && row.input[mappedKey] !== undefined) {
              input[key] = row.input[mappedKey];
            }
          });

          const expected_output = row.expected_output || '';

          return {input, expected_output};
        });

        void onImport(resultArray);
        onClose();
      } catch (e) {
        console.log(
          `Error fetching rows for dataset ${selectedDataset.id}:`,
          e,
        );
      } finally {
        setIsFetchingRows(false);
      }
    }
    setIsFetchingRows(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      hideCloseButton
      isDismissable={false}
      size="4xl"
      radius="none"
    >
      <ModalContent className="flex flex-col items-start justify-start bg-white">
        <ModalHeader className="flex w-full flex-row items-center justify-between bg-[#F9FAFB]">
          <h3 className="font-roboto text-sm font-medium text-[#374151]">
            Import Dataset
          </h3>
          <Button
            isIconOnly={true}
            onClick={onClose}
            radius="full"
            className="h-8 w-8 min-w-0 self-start bg-[#F9FAFB]"
          >
            <XMarkIcon className="h-5" />
          </Button>
        </ModalHeader>
        <ModalBody className="flex w-full flex-col items-start justify-start p-6">
          <Autocomplete
            radius="none"
            labelPlacement="outside"
            placeholder="Select dataset"
            label="Select dataset"
            isLoading={datasetList.loading}
            selectedKey={selectedDataset?.name}
            clearButtonProps={{
              onClick: () => {
                setSelectedDataset(undefined);
                setDatasetList(prevState => ({
                  ...prevState,
                  filter: '',
                }));
              },
            }}
            onSelectionChange={key => {
              if (!key) {
                return;
              }
              const foundDataset = datasetList.datasets.find(d => d.id === key);

              if (!foundDataset) {
                return;
              }

              setSelectedDataset(datasetList.datasets.find(d => d.id === key));
              setDatasetList(prevState => ({
                ...prevState,
                filter: foundDataset.name,
              }));
            }}
            inputValue={datasetList.filter}
            onInputChange={value => {
              if (value !== selectedDataset?.name) {
                setDatasetList(prevState => ({
                  ...prevState,
                  filter: value,
                }));
              }
            }}
            inputProps={{
              classNames: {
                inputWrapper: 'bg-white border[#E9E8E9] border-1',
              },
            }}
          >
            {datasetList.datasets.map(dataset => (
              <AutocompleteItem key={dataset.id}>
                {dataset.name}
              </AutocompleteItem>
            ))}
          </Autocomplete>
          <Switch
            color="default"
            className="font-chivo mt-3 text-xs text-[#1E1E1E]"
            isSelected={allLines}
            onValueChange={setAllLines}
            classNames={{
              wrapper: `${allLines ? '!bg-[#485ED5]' : 'bg-[#9CA3AF]'}`,
            }}
          >
            Include all lines
          </Switch>
          <div className="mt-3 flex flex-col items-start justify-start">
            <span className="font-chivo text-sm font-medium text-[#374151]">
              Select Lines from dataset
            </span>
            <div className="mt-2 flex flex-row items-center justify-between gap-2">
              <Input
                radius="none"
                type="number"
                aria-label="lines-range-from"
                placeholder="1"
                disabled={allLines}
                value={(selectedLines.from + 1).toString()}
                onValueChange={value => {
                  setSelectedLines(prevState => ({
                    ...prevState,
                    from: Math.max(0, parseInt(value) - 1),
                  }));
                }}
                startContent={
                  <span className="font-chivo text-xs font-normal text-[#1E1E1E]">
                    From
                  </span>
                }
                classNames={{
                  inputWrapper: `${!allLines ? 'bg-white' : 'bg-[#c0c0c0]'} border-[#E9E8E9] border-1`,
                }}
              />
              <Input
                radius="none"
                type="number"
                aria-label="lines-range-to"
                disabled={allLines}
                placeholder={selectedDataset?.dataset_rows![0].count.toString()}
                value={(selectedLines.to + 1).toString()}
                onValueChange={value => {
                  setSelectedLines(prevState => ({
                    ...prevState,
                    to: Math.max(
                      selectedLines.from,
                      Math.min(
                        selectedDataset?.dataset_rows![0].count || 0,
                        parseInt(value) - 1,
                      ),
                    ),
                  }));
                }}
                startContent={
                  <span className="font-chivo text-xs font-normal text-[#1E1E1E]">
                    To
                  </span>
                }
                classNames={{
                  inputWrapper: `${!allLines ? 'bg-white' : 'bg-[#c0c0c0]'} border-[#E9E8E9] border-1`,
                }}
              />
            </div>
          </div>
          <div className="mt-3 flex flex-col items-start justify-start">
            <span className="font-chivo text-sm font-medium text-[#374151]">
              Map variables to inputs from dataset
            </span>
            <div className="mt-3 grid grid-cols-[2fr_3fr] gap-2">
              <span className="font-chivo text-xs font-normal text-[#1E1E1E]">
                Variable
              </span>
              <span className="font-chivo text-xs font-normal text-[#1E1E1E]">
                Input from dataset
              </span>
              {Object.keys(mapping).map(input => (
                <>
                  <div className="flex flex-row items-center justify-center">
                    <Chip
                      key={input}
                      color="default"
                      radius="none"
                      classNames={{
                        base: 'w-full max-w-full bg-[#485ED533] rounded-[3px] text-[#485ED5]',
                        content: 'font-chivo text-center text-xs font-semibold',
                      }}
                    >
                      {input}
                    </Chip>
                  </div>
                  <div className="flex flex-row items-center justify-center">
                    <Select
                      aria-label="Select Input"
                      key={input}
                      selectedKeys={new Set([mapping[input]])}
                      radius="none"
                      placeholder="Select Input"
                      selectionMode="single"
                      classNames={{
                        trigger: 'bg-white border-[#E9E8E9] border-1',
                        popoverContent: 'rounded-none',
                      }}
                      onSelectionChange={value => {
                        const selectedKey = String(Array.from(value)[0]);
                        setMapping(prevState => ({
                          ...prevState,
                          [input]: selectedKey,
                        }));
                      }}
                    >
                      {selectedDatasetKeys.map((key: string) => (
                        <SelectItem key={key} value={key}>
                          {key}
                        </SelectItem>
                      ))}
                    </Select>
                  </div>
                </>
              ))}
            </div>
          </div>
          <div className="mb-4 mt-10 flex w-full flex-row items-center justify-center">
            <Button
              aria-label="Import"
              className="bg-[#485ED5] px-14 py-6 text-white"
              radius="none"
              onClick={() => {
                setIsFetchingRows(true);
                void getAndProcessRows();
              }}
            >
              {isFetchingRows ? <Spinner color="white" /> : 'Import'}
            </Button>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ImportDatasetModal;
